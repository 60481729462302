<template>
  <v-app
    :style="FixrolagemFormAberto()"
    class="white"
    light
  >
    <router-view
      :key="$route.fullPath"
    />

    <router-view
      name="functions"
    />

    <router-view
      name="loading"
    />

    <router-view
      name="faqdialog"
    />
  </v-app>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  import GlobalMixinServicesAPI from '@/plugins/mixins/apiServices'
  export default {
    mixins: [
      GlobalMixinServicesAPI
    ],

    computed: {
      ...mapState('Modules', [
        'dialogs'
      ])
    },

    created () {
      if ((Object.keys(this.$route.meta)).indexOf('promo') !== -1) {
        this.ROUTEPROMO({ hasPromo: true, promo: this.$route.meta.promo })
      }
    },

    mounted () {
      this.userDataRegion()
    },

    methods: {
      ...mapMutations('Modules', [ 'ROUTEPROMO' ]),

      FixrolagemFormAberto () {
        if (this.dialogs.form) return `height: 100vh; width: 100vw; overflow: hidden; position: fixed`
      }
    }
  }
</script>
