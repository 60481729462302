import state from '@/plugins/store/dialogs/state'

import * as mutations from '@/plugins/store/dialogs/mutations'
import * as actions from '@/plugins/store/dialogs/actions'

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
