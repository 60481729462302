import Vue from 'vue'
Vue.prototype.env = window.env = (env) => (!env && process.env.VUE_APP_NODE_ENV) || (process.env.VUE_APP_NODE_ENV === env)
Vue.prototype.log = window.log = (...e) => {
  /**
   * HOW USE
   * coloque este trecho abaixo no documento que deseja ver os logs
   * substitua XXXX pelo nome do componente
   * adicione o nome do componente na variável de ambiente (.env.local) VUE_APP_ENABLED_LOGS
   *
   * // eslint-disable-next-line @typescript-eslint/no-unused-vars
   * const LOG = ( ...v ) => window.log( ...v, 'app' )
   *
   * agora insira a const com a mensagem que deseja ver no console.
   * LOG( 'quero ver este log' )
   *
   * Para testar a performance de uma função, basta adicionar um LOG
   * começando com a palavra timeStart e a mensagem que deseja ver
   * e então adicione outro LOG começando com a palavra timeEnd
   * contendo a mesma mensagem do LOG que iniciou com timeStart
   *
   * LOG('timeStart Exemplo de console com tempo de execução')
   * TODA SUA FUNÇÃO AQUI
   * LOG('timeEnd Exemplo de console com tempo de execução')
   *
   * você também pode utilizar o timeStart ao entrar em uma função
   * e o timeEnd ao voltar da função.
   */

  /**
    <script>
      export default {
        data () {
          return {
            LOG: (...v) => window.log(...v, 'app')
          }
        },

        created () {
          this.LOG('timeStart Exemplo de LOG com Time funcionando no arquivo APP')
        },

        mounted () {
          this.LOG('timeEnd Exemplo de LOG com Time funcionando no arquivo APP')
        },

        updated () {
          this.LOG('Exemplo de LOG funcionando com filePath no arquivo APP', '\n\n', __filename)

          try {
            throw Error('Exemplo de LOG com erro funcionando no arquivo APP')
          } catch (error) {
            this.LOG('error on', __filename, error)
          }
        }
      }
    </script>
  */

  try {
      if (/^error on/.test(e[0])) {
          return console[window.env('development') ? 'error' : 'debug'](...e)
      }

      if (window.env('development')) {
          if (/all/.test(process.env.VUE_APP_ENABLED_LOGS)) {
              if (window.location.search) {
                  if (String(window.location.search).replace('?', '').split('=').map(o => o.trim()).includes('debug')) {
                      if (!String(window.location.search).replace('?', '').split('=').map(o => o.trim()).includes(e[e.length - 1])) return
                  } else return
              } else return
          } else {
              if ((!process.env.VUE_APP_ENABLED_LOGS) || (!process.env.VUE_APP_ENABLED_LOGS.split(',').map(o => o.trim()).includes(e[e.length - 1]))) return
          }

          let consoleDefault = true
          if (/timeStart/.test(e[0]) || /timeEnd/.test(e[0])) {
              if (/timeStart/.test(e[0]) && performance) {
                  sessionStorage.setItem('performanceNowStart', performance.now())
              }
              if (/timeEnd/.test(e[0]) && performance) {
                  sessionStorage.setItem('performanceNowEnd', performance.now())
              }

              if (/timeStart/.test(e[0])) {
                  let timeNameFunction = []
                  e[0].split(' ').forEach(string => {
                      if (!/(timeStart|timeEnd)/.test(string)) timeNameFunction.push(string)
                  })

                  if (timeNameFunction.length) {
                      consoleDefault = false

                      if (!sessionStorage.getItem('performanceNowStart')) {
                          console.time(timeNameFunction.join(' '))
                      }
                  }
              }

              if (/timeEnd/.test(e[0])) {
                  let timeNameFunction = []
                  e[0].split(' ').forEach(string => {
                      if (!/(timeStart|timeEnd)/.test(string)) timeNameFunction.push(string)
                  })

                  if (timeNameFunction.length) {
                      consoleDefault = false

                      if (sessionStorage.getItem('performanceNowStart') && sessionStorage.getItem('performanceNowEnd')) {
                          console.log(timeNameFunction.join(' '), sessionStorage.getItem('performanceNowEnd') - sessionStorage.getItem('performanceNowStart'))
                          sessionStorage.removeItem('performanceNowStart')
                          sessionStorage.removeItem('performanceNowEnd')
                      } else {
                          console.timeEnd(timeNameFunction.join(' '))
                      }
                  }
              }
          }

          if (!consoleDefault) return
          let stringItemsReceived = []
          e.forEach(string => {
              if (!process.env.VUE_APP_ENABLED_LOGS.split(',').map(o => o.trim()).includes(string)) stringItemsReceived.push(string)
          })
          console.log(...stringItemsReceived)
      }
  } catch { /* no-empty-catch */ }
}
