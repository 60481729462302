import api from '@/plugins/axios/api'

const mapMessages = require('@/database/autopayment/messages')

function GetReturnUIMessage (id) {
  if (mapMessages.filter(i => i.id === id)[0]) {
    return mapMessages.filter(i => i.id === id)[0]
  } else {
    return mapMessages.filter(i => i.id === 'error')[0]
  }
}

const ShowWelcomePage = ({ commit }, value) => {
  commit('Payment/WELCOMEPAGE', {
    welcome: value
  }, {
    root: true
  })
}

const ShowLoadingModule = ({ commit }, params) => {
  commit('Payment/LOADINGMODULE', {
    status: params.status,
    message: params.message,
    type: params.type
  }, {
    root: true
  })
}

async function getPayload (value) {
  const { data } = await api.get(`/sale/payment/${value}`)
  let dataString = JSON.stringify(data)
  return { ...data, dataString }
}

const Protocolo = ({ rootState, commit }, value) => {
  return new Promise(resolve => {
    try {
      getPayload(value).then(response => {
        if (!response._id) throw new Error('id-not-found')
        if (!response.pedido.produto) throw new Error('id-not-found')

        commit('Payload/PAYLOADPAGAMENTO', {
          '_id': response._id,
          'origem': response.origem,
          'segmento': response.segmento,
          'consumidor': response.consumidor,
          'pedido': JSON.parse(response.dataString).pedido,
          'oportunidade': response.oportunidade
        }, {
          root: true
        })

        commit('Modules/CACHEPAGAMENTO', {
          'pedido': JSON.parse(response.dataString).pedido
        }, {
          root: true
        })

        resolve({
          success: true,
          payload: JSON.stringify(rootState.Payload.payload)
        })
      }).catch(e => {
        resolve({
          success: false,
          message: GetReturnUIMessage(e.message)
        })
      })
    } catch (e) {
      resolve({
        success: false,
        message: GetReturnUIMessage(e.message)
      })
    }
  })
}

const BuscarProtocolo = ({ dispatch }, value) => {
  function ShowLoadingModule (params, time) {
    setTimeout(() => {
      dispatch('Payment/ShowLoadingModule', params, {
        root: true
      })
    }, time)
  }

  ShowLoadingModule({
    status: true,
    type: 'fullscreen',
    message: GetReturnUIMessage('buscando-protocolo')
  })

  return new Promise(resolve => {
    dispatch('Payment/Protocolo',
      value,
    { root: true })

    .then(response => {
      ShowLoadingModule({}, 960)
      try {
        if (!response.success) throw new Error('')
        resolve(response.data.payload)
      } catch {
        resolve(response)
      }
    })
    .catch(ShowLoadingModule({}, 960))
  })
}

export {
  ShowWelcomePage,
  ShowLoadingModule,
  Protocolo,
  BuscarProtocolo
}
