import CryptoJS from 'crypto-js'

const CrypToken = {
  encrypt (payload) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(payload),
      'brocolis-com-azeitona-preta'
    ).toString()
  },

  decrypt (payload) {
    if (payload) {
      const bytes = CryptoJS.AES.decrypt(
        payload,
        'brocolis-com-azeitona-preta'
      )

      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    }

    return false
  }
}

export default CrypToken
