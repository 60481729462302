const DEFAULT_TOOLBAR = () => import(/* webpackChunkName: "tvpre-home" */ '@/views/toolbars/TvpreToolbarView')
const DEFAULT_HERO = () => import(/* webpackChunkName: "tvpre-home" */ '@/views/hero')
const DEFAULT_CARDS = () => import(/* webpackChunkName: "tvpre-home" */ '@/views/cards')
const DEFAULT_MAINFORM = () => import(/* webpackChunkName: "tvpre-home" */ '@/components/forms/MainData')
const DEFAULT_INFORMACOES = () => import(/* webpackChunkName: "tvpre-home" */ '@/views/informacoes')
const DEFAULT_FOOTER = () => import(/* webpackChunkName: "tvpre-home" */ '@/components/home/concept/Footer')

const DEFAULT_INSTALACAO = () => import(/* webpackChunkName: "tvpre-home-cinco" */ '@/views/promo/cinco/instalacao')
const DEFAULT_PACOTESRECARGA = () => import(/* webpackChunkName: "tvpre-home-cinco" */ '@/views/promo/cinco/pacotesrecarga')
const DEFAULT_CAMPANHA_CROSS_SITE = () => import(/* webpackChunkName: "tvpre-home-cinco" */ '@/views/tvpre/faq/CampanhaCrossSite.vue')
const DEFAULT_PERGUNTASFREQUENTES = () => import(/* webpackChunkName: "tvpre-home-cinco" */ '@/views/promo/cinco/perguntasfrequentes')

const PROMO_FOOTER = () => import(/* webpackChunkName: "tvpre-home-cinco" */'@/views/promo/cinco/footer')

export default [
  {
    path: '/:parceiro?',
    components: {
      default: () => import(/* webpackChunkName: "tvpre-home-cinco" */ '@/views/promo/cinco'),
      functions: () => import(/* webpackChunkName: "tvpre-home" */ '@/functions'),
      faqdialog: () => import(/* webpackChunkName: "tvpre-home" */ '@/views/popup/FaqDialog'),
      loading: () => import(/* webpackChunkName: "tvpre-home" */ '@/components/contents/Loading')
    },
    children: [
      {
        path: 'pagamento/:protocolo',
        name: 'pagamento',
        components: {
          getdata: () => import(/* webpackChunkName: "tvpre-pagamento" */ '@/views/formularios/autopayment/GetData'),
          mainform: DEFAULT_MAINFORM
        },
        meta: {
          utm_source: 'webclaro',
          segmento: 'tvpre'
        }
      },

      {
        path: '/s/:atendentetoken',
        name: 'atendente',
        components: {
          toolbar: DEFAULT_TOOLBAR,
          hero: DEFAULT_HERO,
          informacoes: DEFAULT_INFORMACOES,
          cards: DEFAULT_CARDS,
          instalacao: DEFAULT_INSTALACAO,
          pacotesrecarga: DEFAULT_PACOTESRECARGA,
          perguntasfrequentes: DEFAULT_PERGUNTASFREQUENTES,
          footer: DEFAULT_FOOTER,
          mainform: DEFAULT_MAINFORM
        },
        meta: {
          utm_source: 'webclarotvprereceptivonegadopos',
          segmento: 'tvpre',
          functions: ['atendente']
        }
      },

      {
        path: 'pedido/:produto',
        name: 'pedido',
        components: {
          cards: DEFAULT_CARDS,
          mainform: DEFAULT_MAINFORM
        }
      },

      {
        path: 'faq',
        components: {
          toolbar: DEFAULT_TOOLBAR,
          perguntasfrequentes: DEFAULT_PERGUNTASFREQUENTES,
          footer: DEFAULT_FOOTER
        }
      },

      {
        path: 'hd',
        components: {
          hero: DEFAULT_HERO,
          instalacao: DEFAULT_INSTALACAO,
          pacotesrecarga: DEFAULT_PACOTESRECARGA,
          perguntasfrequentes: DEFAULT_PERGUNTASFREQUENTES,
          footer: PROMO_FOOTER,
          mainform: DEFAULT_MAINFORM
        },
        meta: {
          utm_source: 'webclaro',
          segmento: 'tvpre',
          promo: {
            product: 'vtxreceptorhd',
            sku: 'sku_vtxreceptorhd'
          }
        }
      },

      {
        path: '',
        components: {
          toolbar: DEFAULT_TOOLBAR,
          hero: DEFAULT_HERO,
          informacoes: DEFAULT_INFORMACOES,
          cards: DEFAULT_CARDS,
          instalacao: DEFAULT_INSTALACAO,
          pacotesrecarga: DEFAULT_PACOTESRECARGA,
          campanhacrosssite: DEFAULT_CAMPANHA_CROSS_SITE,
          perguntasfrequentes: DEFAULT_PERGUNTASFREQUENTES,
          footer: DEFAULT_FOOTER,
          mainform: DEFAULT_MAINFORM
        },
        meta: {
          utm_source: 'webclaro',
          segmento: 'tvpre'
        }
      }
    ]
  },

  {
    path: '/payment',
    component: () => import(/* webpackChunkName: "tvpre-pagamento" */ '@/components/contents/tvpre/yapay/Resumo')
  },

  {
    path: '*',
    redirect: '/'
  }
]
