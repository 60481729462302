import api from '@/plugins/axios/api'

function validatecep (value) {
  const cep = value
  const validateCep = /[0-9]{8}/g
  if (validateCep.test(cep)) {
    return cep.replace(/(\d{5})?(\d{3})/, '$1-$2')
  }
}

async function getInstaller ({ commit }, param) {
  try {
    const list = (await api.get(`/installer/list/${validatecep(param)}`)).data.installers
     list.sort(function (a, b) { return b.isPremium - a.isPremium })
    commit('SETINSTALLER', { list })
  } catch (error) {
     commit('SETINSTALLER', {})
  }
}

export {
  getInstaller
}
