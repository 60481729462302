import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@/assets/stylus/index.styl'
import '@/assets/stylus/font.styl'
import '@/assets/stylus/border.styl'
import '@/assets/stylus/shadow.styl'
import '@/assets/stylus/filter.styl'
import '@/assets/stylus/color.styl'
import '@/assets/stylus/background.styl'
import '@/assets/stylus/align.styl'
import '@/assets/stylus/width.styl'
import '@/assets/stylus/height.styl'
import '@/assets/stylus/padding.styl'
import '@/assets/stylus/margin.styl'
import './directives'

try {
  /* eslint-disable-next-line no-restricted-syntax */
  for (const element of document.getElementsByTagName('html')) element.style.overflowY = 'auto'
} catch { /* eslint-disable-next-line no-empty */ }

Vue.use(Vuetify, {
  options: {
    customProperties: true
  },
  iconfont: 'md' || 'fa',
  theme: {
    'brand-principal': '#e3262e',
    'brand-dark': '#a61d19',
    'brand-second': '#c22519',
    'brand-second-light': '#ff837a',
    'brand-disabled': '#ff6256',
    'yellow-principal': '#fbb235',
    'yellow-modal': '#de7801',
    'yellow-support': '#753f00',
    'breadcrumb-first': '#931d15',
    'breadcrumb-second': '#b31005',
    'breadcrumb-third': '#cf3c32',
    'grey-brand': '#5f646d',
    'grey-regular': '#949494',
    'grey-light': '#f4f4f4',
    'grey-medium': '#dddddd',
    'grey-dark': '#444444',
    'grey-bold': '#202020',
    'ruby': 'ed1852',
    'a20-background-texto-legal': '#f3f3f3',
    'a20-cta': '#e32620',
    'a20-titulo': '#222222',
    'a20-descricao': '#acacac',
    'blackfriday-cta': '#ffd400',
    'blackfriday-divider': '#272727',
    'blackfriday-descricao': '#777777',

    'brand-lebes-cta': '#2fb12b',
    'brand-novomundo-cta': '#0357a2',
    'brand-colombo-cta': '#3372DF'
  }
})
