const objectutm = require('@/data/utm')

const findQueryes = (query) => {
  function compareObject (map, url, option) {
    let aProps = Object.getOwnPropertyNames(map)

    for (let key in aProps) {
      let propName = aProps[key]

      let aCase = option ? String(map[propName]).toLowerCase() : map[propName]
      let bCase = option ? String(url[propName]).toLowerCase() : url[propName]

      if (aCase !== bCase) {
        return false
      }
    }

    return true
  }

  for (let key in Object.keys(objectutm.map(o => o.query))) {
    if (compareObject(objectutm.map(o => o.query)[key], Object(query), true)) {
      return objectutm.map(o => o.origem)[key]
    }
  }
}

export {
  findQueryes
}
