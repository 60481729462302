const OPENFAQ = ({
  faq
}, {
  active,
  component
}) => {
  faq.active = active
  faq.component = component
}

const CLOSEFAQ = ({
  faq
}, {
  active,
  component
}) => {
  faq.active = active
  faq.component = component
}

export {
  OPENFAQ,
  CLOSEFAQ
}
