const openFaq = ({ commit }, component) => {
  commit('Dialogs/OPENFAQ', {
    'active': true,
    'component': component
  }, { root: true })
}

const closeFaq = ({ commit }) => {
  commit('Dialogs/CLOSEFAQ', {
    'active': false,
    'component': undefined
  }, { root: true })
}

export {
  openFaq,
  closeFaq
}
