import { Component } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { namespace } from 'vuex-class'
import Axios from 'axios'
import ibgeData from '@/data/global/ibgeEstados.json'
// import { ServicesProductAPI } from '@/plugins/api/servicesProductAPI'
import { viaCepFields } from '@/helpers/getViacepValues'
const cacheStore = namespace('GlobalCache')
@Component
class ServicesTrackingAPI extends mixins(
  // ServicesProductAPI
) {
  @cacheStore.Getter('RastreamentoUsuario') getCacheRastreamentoUsuario
  testIpProductionKey = (location.search && (/debug/.test(location.search) && /testipproductionkey/.test(location.search)))
  testBrowserLocation = (location.search && (/debug/.test(location.search) && /testbrowserlocation/.test(location.search)))
  testOnlineLocation = (location.search && (/debug/.test(location.search) && /testonlinelocation/.test(location.search)))
  ibgeData = ibgeData
  userDataRegionView = {
    ddd: '',
    city: '',
    region: '',
    region_code: ''
  }

  async userDataRegion () {
    let userDataRegion = this.getCacheRastreamentoUsuario()
    if (Object.keys(userDataRegion).length) {
      this.userDataRegionView = userDataRegion
    } else {
      userDataRegion = await this.serviceAPIIPData()
      this.userDataRegionView = userDataRegion
    }
  }

  serviceAPIIPData () {
    function returnCacheData (cacheDataReceived, resolve, dispatch) {
      if (cacheDataReceived) {
        dispatch.serviceAPIRegionData(cacheDataReceived.data || cacheDataReceived).then(regionResponse => {
          sessionStorage.setItem('GeoTag', JSON.stringify(dispatch.getCacheRastreamentoUsuario({
            ...(cacheDataReceived.data || cacheDataReceived),
            ...regionResponse
          })))
        }).finally(() => {
          resolve(dispatch.getCacheRastreamentoUsuario())
        })
      }
    }

    async function runSupportLocationAPI (resolve, dispatch) {
      /** @todo
       * Verificar se o usuário já escolheu uma localização por meio do Botão
       * Verificar o DDD do usuário se já está escolhido e então não chamar a API
      */
      if (sessionStorage.getItem('GeoTag') && Object.keys(JSON.parse(sessionStorage.getItem('GeoTag') || ''))) {
        returnCacheData(dispatch.getCacheRastreamentoUsuario(JSON.parse(sessionStorage.getItem('GeoTag') || '')), resolve, dispatch)
      } else {
        const TEST_USE_API_KEY = window.env('production') || dispatch.testIpProductionKey
        let locationAPIResponse
        if (!locationAPIResponse) {
          // 1 - 30.000 /mês - https://ipapi.co/#pricing
          locationAPIResponse = await Axios.get(`https://ipapi.co/json/${TEST_USE_API_KEY ? '?key=l6eA40p9jhrbza4rf79kDwlyiiVr920h9FRYSX0o5X0mQaoLcQ' : ''}`).catch(() => { /* no-empty-catch */ })
        }
        if (!locationAPIResponse) {
          // 2 - 50.000 /mês - https://ipinfo.io/pricing
          locationAPIResponse = await Axios.get(`https://ipinfo.io/${TEST_USE_API_KEY ? '?token=f077df2728be91' : ''}`).catch(() => { /* no-empty-catch */ })
        }
        if (!locationAPIResponse) {
          // 3 - 10.000 /mês - https://ipwhois.io/pricing
          locationAPIResponse = await Axios.get(`https://ipwhois.app/json/${TEST_USE_API_KEY ? '' : ''}`).catch(() => { /* no-empty-catch */ })
        }
        if (!locationAPIResponse) {
          // 4 - 1.500 /dia - https://ipdata.co/pricing.html
          locationAPIResponse = await Axios.get(`https://api.ipdata.co/${TEST_USE_API_KEY ? '?api-key=a8aa1abf5d8099c996992e1e33561c0104b524378334519b9ce061e5' : '?api-key=test'}`).catch(() => { /* no-empty-catch */ })
        }
        if (!locationAPIResponse) {
          // 5 - 30.000 /mês com limite de 1.000 /dia - https://ipgeolocation.io/pricing.html
          locationAPIResponse = await Axios.get(`https://api.ipgeolocation.io/ipgeo/${TEST_USE_API_KEY ? '?apiKey=84044bbae6f2403596e4ad3a0b06c19e' : ''}`).catch(() => { /* no-empty-catch */ })
        }
        if (locationAPIResponse) returnCacheData(locationAPIResponse, resolve, dispatch)
      }
    }

    function runGetLocationData (position, resolve, dispatch) {
      Axios.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${
        position.coords.latitude
      }&longitude=${
        position.coords.longitude
      }`).then((responseLatLong) => {
        returnCacheData(responseLatLong, resolve, dispatch)
      }).catch((errorLatLong) => {
        window.log('error on - BigDataCloud', errorLatLong)
        runSupportLocationAPI(resolve, dispatch)
      })
    }

    function getUserCurrentLocation (resolve, dispatch) {
      const WATCH_POSITION_ID = navigator.geolocation.watchPosition((position) => {
        // navigator.geolocation.getCurrentPosition((position) => {})
        navigator.geolocation.clearWatch(WATCH_POSITION_ID)
        /** @Note
         * O Google da crédito para testes mas exige um cadastro que não estou conseguindo finalizar.
         * Quando tento validar o CPF e Nascimento, manda entrar em contato com support mas não
         * consegui contato ainda.
          get(`https://maps.googleapis.com/maps/api/geocode/json?key=&latlng=${
            position.coords.latitude
          },${
            position.coords.longitude
          }`)
        */

        if (sessionStorage.getItem('GeoTag') && Object.keys(JSON.parse(sessionStorage.getItem('GeoTag') || ''))) {
          const CACHE_DATA = dispatch.getCacheRastreamentoUsuario(JSON.parse(sessionStorage.getItem('GeoTag') || ''))
          if ([
            Math.round(CACHE_DATA.latitude) === Math.round(position.coords.latitude),
            Math.round(CACHE_DATA.longitude) === Math.round(position.coords.longitude)
          ].every(o => !!o)) {
            returnCacheData(CACHE_DATA, resolve, dispatch)
          } else {
            runGetLocationData(position, resolve, dispatch)
          }
        } else {
          runGetLocationData(position, resolve, dispatch)
        }
      }, () => {
        navigator.geolocation.clearWatch(WATCH_POSITION_ID)
        runSupportLocationAPI(resolve, dispatch)
      }, {
        enableHighAccuracy: true,
        timeout: 8000,
        maximumAge: 0
      })
    }

    return new Promise(resolve => {
      if (sessionStorage.getItem('GeoTag') && Object.keys(JSON.parse(sessionStorage.getItem('GeoTag') || ''))) {
        if (viaCepFields('ddd') && viaCepFields('uf')) {
          resolve(this.getCacheRastreamentoUsuario(JSON.parse(sessionStorage.getItem('GeoTag') || '')))
        } else {
          returnCacheData(JSON.parse(sessionStorage.getItem('GeoTag') || ''), resolve, this)
        }
      } else {
        switch (true) {
          case this.testOnlineLocation:
            setTimeout(() => {
              sessionStorage.removeItem('GeoTag')
              sessionStorage.removeItem('viacep')
            }, 8000)
            if (this.testBrowserLocation) {
              getUserCurrentLocation(resolve, this)
            } else {
              runSupportLocationAPI(resolve, this)
            }
            break
          default:
            getUserCurrentLocation(resolve, this)
        }
      }
    })
  }

  serviceAPIRegionData (data) {
    return new Promise(resolve => {
      if (viaCepFields('ddd') && viaCepFields('uf')) {
        resolve({
          ddd: viaCepFields('ddd'),
          region_code: viaCepFields('uf')
        })
      } else {
        let regionStateUserDDD
        let regionStateUserUF = data ? (data.region_code || '') : ''
        let regionStateUserCity = data ? (data.city || data.region || data.principalSubdivision || '') : ''
        let regionStateUserNeighborhood = data ? (data.region || data.city || data.principalSubdivision || '') : ''

        ibgeData.filter((ibgeDataItem) => {
          switch (true) {
            case (ibgeDataItem.nome.includes(data ? (data.city) : '')):
              regionStateUserUF = ibgeDataItem.sigla
              regionStateUserCity = regionStateUserNeighborhood = ibgeDataItem.nome[1]
              break
            case (ibgeDataItem.nome.includes(data ? (data.region) : '')):
              regionStateUserUF = ibgeDataItem.sigla
              regionStateUserCity = regionStateUserNeighborhood = ibgeDataItem.nome[1]
              break
            case (ibgeDataItem.nome.includes(data ? (data.principalSubdivision) : '')):
              regionStateUserUF = ibgeDataItem.sigla
              regionStateUserCity = regionStateUserNeighborhood = ibgeDataItem.nome[1]
              break
          }
        })

        if (regionStateUserUF && regionStateUserCity && regionStateUserNeighborhood) {
          Axios.get(
            `https://viacep.com.br/ws/${regionStateUserUF}/${String(regionStateUserCity).replace(/\s/g, '%20')}/${String(regionStateUserNeighborhood).replace(/\s/g, '%20')}/json`
          ).then(regionResponse => {
            let regionDataSaved
            if (regionResponse.data.erro) {
              regionDataSaved = { 'uf': regionStateUserUF, 'erro': true }
            } else {
              (regionResponse.data || regionResponse).forEach(regionItem => {
                regionStateUserDDD = regionItem ? (regionItem.ddd || this.getCacheRastreamentoUsuario().ddd) : this.getCacheRastreamentoUsuario().ddd
                regionDataSaved = regionItem
              })
            }
            sessionStorage.setItem('viacep', JSON.stringify(regionDataSaved || {}))
            resolve({
              ddd: regionStateUserDDD,
              region_code: regionStateUserUF
            })
          })
        }
      }
    })
  }
}

export default ServicesTrackingAPI
