const KeyFind = require('@/plugins/mixins/keyfind.js').default.methods.keyFind

const findAtendente = (to, next) => {
  if (KeyFind(to.params, 'atendentetoken')) {
    const AtendenteToken = require('@/test/atendente/vertex.json')
    if (Object.values(AtendenteToken).map(o => o.token === to.params.atendentetoken).indexOf(true) < 0) next('/')
  }
}

export {
  findAtendente
}
