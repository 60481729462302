const WELCOMEPAGE = ({
  ui
}, {
  welcome
}) => {
  ui.welcome = welcome
}

const LOADINGMODULE = ({
  ui
},
  params
) => {
  ui.loading = params
}

export {
  WELCOMEPAGE,
  LOADINGMODULE
}
