import state from '@/plugins/store/state'

import * as getters from '@/plugins/store/getters'
import * as mutations from '@/plugins/store/mutations'
import * as actions from '@/plugins/store/actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
