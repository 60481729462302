import Vue from 'vue'
import Vuex from 'vuex'

import Modules from '@/plugins/store/modules'
import Payload from '@/plugins/store/payload'
import Dialogs from '@/plugins/store/dialogs'
import Installers from '@/plugins/store/installers'

import Payment from '@/plugins/store/autopayment/index'

/** @PDVLojaPropria */
import { LojaPropria } from '@/plugins/store/pdv'
/** @PDVLojaPropria */

/** @RedeInova */
import RedeInova from '@/plugins/store/redeinova'
/** @RedeInova */

import {
  M4UStore
} from '@/plugins/store/m4u'

import GlobalCache from '@/plugins/store/cache'
import { cacheStoreModule } from '@/plugins/store/cacheStore/cacheStoreModule'

Vue.use(Vuex)

export default function () {
  const Store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',

    modules: {
      Modules,
      Payload,
      Dialogs,
      Installers,
      Payment,
      LojaPropria,
      RedeInova,
      M4U: M4UStore,
      GlobalCache,
      cacheStoreModule
    }
  })

  return Store
}
