const Axios = require('axios')

const CloseOferta = ({ commit, state, rootState }, param) => {
  setTimeout(
    () => {
      commit('LOADING', true)
    }, 1920
  )

  commit('FORMDATAOPENED', false)
}

const loading = ({ commit }, param) => {
  setTimeout(
    () => {
      commit('LOADING', param.value)
    }, param.time ? param.time : 1920
  )
}

const windowsize = ({ commit }) => {
  commit('WINDOWSIZE',
    {
      x: window.innerWidth,
      y: window.innerHeight
    }
  )
}

const Checkdisable = ({ state, commit }, param) => {
  if (state.formData.pagamento.disables.planMix.active) {
    let disable

    if ('uf' in param) disable = state.formData.pagamento.disables.planMix.uf.includes(param.uf)

    if ('ddd' in param && state.cache.servico.plano.franquia) {
      disable = state.formData.pagamento.disables.planMix.ddd.map(String).includes(param.ddd)

      if (disable && !state.formData.pagamento.disables.planMix.validate) {
        commit('PAYLOAD',
          {
            planGb: (parseInt(String(state.cache.servico.plano.franquia).replace(/gb/i, '')) + state.formData.pagamento.disables.planMix.value) + 'GB'
          }
        )
      }

      if (!disable && state.formData.pagamento.disables.planMix.validate) {
        commit('PAYLOAD',
          {
            planGb: (String(state.cache.servico.plano.franquia).replace(/gb/i, '') - state.formData.pagamento.disables.planMix.value) + 'GB'
          }
        )
      }
    }

    commit('CHECKDDDUF',
      {
        key: 'planMix',
        value: disable
      }
    )
  }

  if (state.formData.pagamento.bonus.portabilidade.active && 'ddd' in param) {
    let disable

    disable = state.formData.pagamento.bonus.portabilidade.estados.includes(parseInt(param.ddd))

    if (param.franquia && param.bonus) {
      if (disable) {
        commit('PAYLOAD',
          {
            planGb: (Number(String(param.franquia).replace(/gb/i, '')) + Number(param.bonus)) + 'GB'
          }
        )
      }

      if (!disable) {
        commit('PAYLOAD',
          {
            planGb: String(param.franquia).replace(/gb/i, '') + 'GB'
          }
        )
      }
    }

    commit('TOGGLE', {
      ref: 'stateformDatapagamentobonusportabilidadevalidate',
      key: disable
    })
  }

  if ('uf' in param) {
    commit('SAVEDDDUF',
      {
        uf: param.uf
      }
    )
  }

  if ('ddd' in param) {
    commit('SAVEDDDUF',
      {
        ddd: param.ddd
      }
    )
  }
}

const getLead = ({ state, commit }, param) => {
  let response

  switch (param.step) {
    case 'vamoscomecar':
      return new Promise((resolve, reject) => {
        Axios.get('https://claro-get-lead.vertex.workers.dev')
          .then(function (response) {
            if (response.data.consumidor.cpf !== param.cpf) throw new Error('erro')

            commit('Modules/PAYLOAD',
              {
                consumidorCpf: param.cpf,
                consumidorEmail: response.data.consumidor.email,
                consumidorTelefoneContato: response.data.consumidor.telefone.contato,
                consumidorTelefoneServico: response.data.consumidor.telefone.servico,
                consumidorName: response.data.consumidor.nome,
                consumidorFiliacao: response.data.consumidor.filiacao,
                consumidorNascimento: response.data.consumidor.nascimento,
                consumidorEnderecoCep: response.data.consumidor.endereco.cep,
                consumidorEnderecoLogradouro: response.data.consumidor.endereco.logradouro,
                consumidorEnderecoBairro: response.data.consumidor.endereco.bairro,
                consumidorEnderecoCidade: response.data.consumidor.endereco.cidade,
                consumidorEnderecoUf: response.data.consumidor.endereco.uf,
                consumidorEnderecoNumero: response.data.consumidor.endereco.numero,
                consumidorEnderecoComplemento: response.data.consumidor.endereco.complemento,
                servicoPagamentoDataVencimento: response.data.servico.pagamento.dataVencimento
              },
              { root: true }
            )

            resolve(response.data)
          })

          .catch(function () {
            reject(true)
          })
      })
    case 'escolhaservico':
      response = {
        data: {
          consumidor: {
            telefone: {
              servico: state.payload.consumidor.telefone.servico
            }
          }
        }
      }
      return new Promise((resolve, reject) => {
        if (!response.data.consumidor.telefone.servico) reject(true)
        resolve(response.data)
      })
  }
}

export {
  CloseOferta,
  loading,
  windowsize,
  Checkdisable,
  getLead
}
