module.exports = [
  {
    id: 'pedido-em-processamento',
    message: `
    Recebemos o seu pedido e estamos processando!
    `
  },

  {
    id: 'pagamento-nao-efetuado',
    message: `
    O pagamento não pode ser concluído.<br>
    Por favor, tente novamente!
    `
  },

  {
    id: 'pagamento-nao-encontrado',
    message: `
    Houve um erro no pagamento.<br>
    Por favor, tente novamente!
    `
  },

  {
    id: 'error',
    message: `
    Desculpe, não conseguimos processar sua solicitação.<br>
    Por favor, tente novamente!
    `
  }
]
