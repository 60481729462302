import axios from 'axios'

let instance = axios.create({
  baseURL: process.env.VUE_APP_BRAND_API,
  responseType: 'json',
  headers: {
    'Authorization': process.env.VUE_APP_APPLICATION_KEY
  }
})

export default instance
