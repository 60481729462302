// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const LOG = (...v) => window.log(__filename, '\n\n', ...v, 'app')
// eslint-disable-next-line no-unused-vars
const ENV = (env) => window.env(env)

export const afilioPurchasePixel = (payload) => {
  try {
    if ([
      payload.analytics.params.id_click,
      /vemqda/.test(payload.analytics.source)
    ].every((o) => !!o)) {
      if (!document.querySelector('img[src*="https://p.afilio.com.br"]')) {
        const image = document.createElement('img')
        image.src = `https://p.afilio.com.br/?tra_id=${payload.oportunidade.id}&price=${payload.pedido.valorTotal}&act_id=1818&id_click=${payload.analytics.params.id_click}`
        document.body.appendChild(image)
      }
    }
  } catch {
    LOG('Error on - afilioPurchasePixel')
  }
}
export const meuCupomPurchasePixel = (payload) => {
  try {
    if ([
      payload.analytics.params.aff_click_id,
      /vemqda/.test(payload.analytics.source)
    ].every((o) => !!o)) {
      if (!document.querySelector('iframe[src*="https://indexanetwork.go2cloud.org"]')) {
        const iframeOld = document.createElement('iframe')

        iframeOld.src = `https://indexanetwork.go2cloud.org/aff_l?offer_id=2814&adv_sub=${payload.oportunidade.id}&conversion_unique_id=${payload.oportunidade.id}&amount=${payload.pedido.valorTotal}`

        document.body.appendChild(iframeOld)
      }
    }
  } catch {
    LOG('Error on - meuCupomPurchasePixel')
  }
}
