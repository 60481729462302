import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Document = require('@/plugins/router/document')
const Functions = require('@/plugins/router/function')

const router = new VueRouter({
  mode: 'history',

  routes: [].concat(
    Document.default
  )
})

router.beforeEach((to, from, next) => {
  to.meta.utm_source = Functions.findQueryes(to.query) || to.meta.utm_source

  /** @Teste_url_production */
  if (window.env('production')) {
    if (/concept|redeinova/.test(to.path)) location.replace(location.origin)
    if ((/(promo[1-6]\/?$)/).test(to.path)) location.replace(location.origin)
    if ((/promo[1-7]\/?$/).test(to.path)) {
      if (!location.search) location.replace(location.origin)
      if ((/^webclaro$/).test(to.meta.utm_source)) location.replace(location.origin)
    }
  }
  /** @Teste_url_production */

  Functions.metaUpdate(to)
  Functions.findAtendente(to, next)

  next()
})

export default () => {
  return router
}
