module.exports = [
  {
    url: `${location.origin}?utm_source=SMS&utm_medium=Pos&utm_campaign=Parceiro`,
    origem: 'webclarosmsposparceiro',
    query: {
      'utm_source': 'SMS',
      'utm_medium': 'Pos',
      'utm_campaign': 'Parceiro'
    },
    function: ['popup-welcome']
  },
  {
    url: `${location.origin}?utm_source=Visiontec&utm_medium=Site`,
    origem: 'webclarovisiontecsite',
    query: {
      'utm_source': 'Visiontec',
      'utm_medium': 'Site'
    }
  },
  {
    url: `${location.origin}?utm_source=site&utm_medium=claro`,
    origem: 'webclarotvpresiteclaro',
    query: {
      'utm_source': 'site',
      'utm_medium': 'claro'
    }
  },
  {
    url: `${location.origin}?utm_source=SMS&utm_medium=Carrinho&utm_campaign=Abandono`,
    origem: 'webclarosmscarrinhoabandono',
    query: {
      'utm_source': 'SMS',
      'utm_medium': 'Carrinho',
      'utm_campaign': 'Abandono'
    }
  },
  {
    url: `${location.origin}?utm_source=email&utm_medium=negado_pos`,
    origem: 'webclarotvpreemailnegadopos',
    query: {
      'utm_source': 'email',
      'utm_medium': 'negado_pos'
    },
    function: ['popup-welcome']
  },

  {
    url: `${location.origin}?utm_source=Push&utm_medium=ClaroPos`,
    origem: 'webclarotvprepushtvpos',
    query: {
      'utm_source': 'Push',
      'utm_medium': 'ClaroPos'
    }
  },

  {
    url: `${location.origin}/promo1?utm_source=promo1&utm_medium=sms&utm_campaign=kitantena`,
    origem: 'webclaropromokitantena',
    query: {
      'utm_source': 'promo1',
      'utm_medium': 'sms',
      'utm_campaign': 'kitantena'
    }
  },

  {
    url: `${location.origin}/promo2?utm_source=promo2&utm_medium=sms&utm_campaign=hd`,
    origem: 'webclaropromoreceptorhdcanais',
    query: {
      'utm_source': 'promo2',
      'utm_medium': 'sms',
      'utm_campaign': 'hd'
    }
  },
  {
    url: `${location.origin}/promo3?utm_source=promo3&utm_medium=sms`,
    origem: 'webclaropromokitcanais',
    query: {
      'utm_source': 'promo3',
      'utm_medium': 'sms'
    }
  },
  {
    url: `${location.origin}/promo4?utm_source=promo4&utm_medium=sms`,
    origem: 'webclaropromoreceptorhd',
    query: {
      'utm_source': 'promo4',
      'utm_medium': 'sms'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=sms`,
    origem: 'webclarotvpresmsbaseclaro',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'sms'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basevtx_email`,
    origem: 'webclarotvprebasevertexemail',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basevtx_email'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basevtx_email&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexemail',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basevtx_email',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basevtx_sms`,
    origem: 'webclarotvprebasevertexsms',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basevtx_sms'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basevtx_sms&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexsms',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basevtx_sms',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basevtx_wapp`,
    origem: 'webclarotvprebasevertexwhatsapp',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basevtx_wapp'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basevtx_wapp&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexwhatsapp',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basevtx_wapp',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=basectrlvtx`,
    origem: 'webclarotvprebasevertexctrl149',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'basectrlvtx'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=email_negado&utm_campaign=d30`,
    origem: 'preemailnutricaonegado',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'email_negado',
      'utm_campaign': 'd30'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=email_negado&utm_campaign=d60`,
    origem: 'preemailnutricaonegado',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'email_negado',
      'utm_campaign': 'd60'
    }
  },
  {
    url: `${location.origin}/promo5?utm_source=promo5&utm_medium=email_negado&utm_campaign=d90`,
    origem: 'preemailnutricaonegado',
    query: {
      'utm_source': 'promo5',
      'utm_medium': 'email_negado',
      'utm_campaign': 'd90'
    }
  },
  {
    url: `${location.origin}?utm_source=sms&utm_medium=oferta_ss`,
    origem: 'webclaroofertass',
    query: {
      'utm_source': 'sms',
      'utm_medium': 'oferta_ss'
    }
  },

  {
    url: `${location.origin}?utm_source=oferta&utm_medium=2meses`,
    origem: 'webclarooferta2meses',
    query: {
      'utm_source': 'oferta',
      'utm_medium': '2meses'
    }
  },

  {
    url: `${location.origin}?utm_source=carrinho_blt`,
    origem: 'webclarotvpreboletovencido',
    query: {
      'utm_source': 'carrinho_blt'
    }
  },

  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basevtx_email`,
    origem: 'webclarotvprebasevertexemail199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basevtx_email'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basevtx_email&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexemail199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basevtx_email',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basevtx_sms`,
    origem: 'webclarotvprebasevertexsms199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basevtx_sms'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basevtx_sms&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexsms199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basevtx_sms',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basevtx_wapp`,
    origem: 'webclarotvprebasevertexwhatsapp199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basevtx_wapp'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basevtx_wapp&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexwhatsapp199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basevtx_wapp',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basectrlvtx`,
    origem: 'webclarotvprebasevertexctrl199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basectrlvtx'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basectrlvtx_wapp`,
    origem: 'webclarotvprebasevertexctrlwapp199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basectrlvtx_wapp'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basectrlvtx_wapp&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexctrlwapp199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basectrlvtx_wapp',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basectrlvtx_email`,
    origem: 'webclarotvprebasevertexctrlemail199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basectrlvtx_email'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=basectrlvtx_email&utm_campaign=carrinho`,
    origem: 'webclarotvprebasevertexctrlemail199',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'basectrlvtx_email',
      'utm_campaign': 'carrinho'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=email_negado&utm_campaign=d30`,
    origem: 'preemailnutricaonegado',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'email_negado',
      'utm_campaign': 'd30'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=email_negado&utm_campaign=d60`,
    origem: 'preemailnutricaonegado',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'email_negado',
      'utm_campaign': 'd60'
    }
  },
  {
    url: `${location.origin}/promo6?utm_source=promo6&utm_medium=email_negado&utm_campaign=d90`,
    origem: 'preemailnutricaonegado',
    query: {
      'utm_source': 'promo6',
      'utm_medium': 'email_negado',
      'utm_campaign': 'd90'
    }
  },
  {
    url: `${location.origin}?utm_source=pcsemailmkt`,
    origem: 'webclarotvprebannerincentivoemailmkt',
    query: {
      'utm_source': 'pcsemailmkt'
    }
  },
  {
    url: `${location.origin}?utm_source=livestore`,
    origem: 'webclarotvprelivestore',
    query: {
      'utm_source': 'livestore'
    }
  },
  {
    url: `${location.origin}?utm_source=google&utm_medium=cpc&utm_campaign=id_google-search_aquisicao_claro-tv-pre_conversao_pedido_aberto_always-on_br_aon-aq-tv-prepago_institucional_tv-pre-pago`,
    origem: 'webclarotvpreclarogoogleadsoperadora',
    query: {
      'utm_source': 'google',
      'utm_medium': 'cpc',
      'utm_campaign': 'id_google-search_aquisicao_claro-tv-pre_conversao_pedido_aberto_always-on_br_aon-aq-tv-prepago_institucional_tv-pre-pago'
    }
  },
  {
    url: `${location.origin}?utm_source=google&utm_medium=cpc&utm_campaign=id_google-search_aquisicao_claro-tv-pre_conversao_pedido_aberto_always-on_br_aon-aq-tv-prepago_generica_tv-pre-pago`,
    origem: 'webclarotvpreclarogoogleadsoperadora',
    query: {
      'utm_source': 'google',
      'utm_medium': 'cpc',
      'utm_campaign': 'id_google-search_aquisicao_claro-tv-pre_conversao_pedido_aberto_always-on_br_aon-aq-tv-prepago_generica_tv-pre-pago'
    }
  },
  {
    url: `${location.origin}?utm_source=Claro&utm_medium=Google&utm_campaign=AdsOperadora`,
    origem: 'webclarotvpreclarogoogleadsoperadora',
    query: {
      'utm_source': 'Claro',
      'utm_medium': 'Google',
      'utm_campaign': 'AdsOperadora'
    }
  },
  {
    url: `${location.origin}/promo7?utm_source=promo7&utm_medium=sms_claro_bf`,
    origem: 'webclarotvpresmsbaseclaroblackfriday',
    query: {
      'utm_source': 'promo7',
      'utm_medium': 'sms_claro_bf'
    }
  },
  {
    url: `${location.origin}?utm_source=caixatem`,
    origem: 'webclarotvprecaixatem',
    query: {
      'utm_source': 'caixatem'
    }
  },
  {
    url: `${location.origin}?utm_source=mundiale`,
    origem: 'chatmundiale',
    query: {
      'utm_source': 'mundiale'
    },
    function: ['popup-welcome']
  },
  {
    url: `${location.origin}/bancointer?utm_source=bancointer`,
    origem: 'webclarotvprebancointer',
    query: {
      'utm_source': 'bancointer'
    }
  },
  {
    url: `${location.origin}/bancointer?utm_source=bancointer&utm_campaign=sms_carrinho`,
    origem: 'webclarotvprebancointer',
    query: {
      'utm_source': 'bancointer',
      'utm_campaign': 'sms_carrinho'
    }
  },
  {
    url: `${location.origin}/bancointer?utm_source=bancointer&utm_campaign=email_carrinho`,
    origem: 'webclarotvprebancointer',
    query: {
      'utm_source': 'bancointer',
      'utm_campaign': 'email_carrinho'
    }
  },
  {
    url: `${location.origin}?utm_source=stefanini`,
    origem: 'webclarotvprestefanini',
    query: {
      'utm_source': 'stefanini'
    }
  },
  {
    url: `${location.origin}?utm_source=stefanini&utm_campaign=sms_carrinho`,
    origem: 'webclarotvprestefanini',
    query: {
      'utm_source': 'stefanini',
      'utm_campaign': 'sms_carrinho'
    }
  },
  {
    url: `${location.origin}?utm_source=stefanini&utm_campaign=email_carrinho`,
    origem: 'webclarotvprestefanini',
    query: {
      'utm_source': 'stefanini',
      'utm_campaign': 'email_carrinho'
    }
  },

  {
    url: `${location.origin}?utm_source=sms_basepre&utm_medium=carrinho`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'sms_basepre',
      'utm_medium': 'carrinho'
    }
  },
  {
    url: `${location.origin}?utm_source=sms_basepre&utm_medium=compra`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'sms_basepre',
      'utm_medium': 'compra'
    }
  },
  {
    url: `${location.origin}?utm_source=sms_basepre&utm_medium=prospect`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'sms_basepre',
      'utm_medium': 'prospect'
    }
  },
  {
    url: `${location.origin}?utm_source=email_basepre&utm_medium=carrinho`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'email_basepre',
      'utm_medium': 'carrinho'
    }
  },
  {
    url: `${location.origin}?utm_source=email_basepre&utm_medium=pedidoconcluido`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'email_basepre',
      'utm_medium': 'pedidoconcluido'
    }
  },
  {
    url: `${location.origin}?utm_source=email_basepre&utm_medium=compra`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'email_basepre',
      'utm_medium': 'compra'
    }
  },
  {
    url: `${location.origin}?utm_source=email_basepre&utm_medium=prospect`,
    origem: 'webclarotvprecrossprezao',
    query: {
      'utm_source': 'email_basepre',
      'utm_medium': 'prospect'
    }
  },
  {
    url: `${location.origin}?utm_source=popup_basepre&utm_medium=saidalp`,
    origem: 'webclarotvprepopupprezao',
    query: {
      'utm_source': 'popup_basepre',
      'utm_medium': 'saidalp'
    }
  },

  {
    url: '/?utm_source=crosslanding&utm_medium=controle_tvpre',
    query: {
      'utm_source': 'crosslanding',
      'utm_medium': 'controle_tvpre'
    },
    origem: 'webcrossbannerslanding'
  },
  {
    url: '/?utm_source=crosslanding&utm_medium=pos_tvpre',
    query: {
      'utm_source': 'crosslanding',
      'utm_medium': 'pos_tvpre'
    },
    origem: 'webcrossbannerslanding'
  },
  {
    url: '/?utm_source=crosslanding&utm_medium=pre_tvpre',
    query: {
      'utm_source': 'crosslanding',
      'utm_medium': 'pre_tvpre'
    },
    origem: 'webcrossbannerslanding'
  },
  {
    url: '/?utm_source=crosslanding&utm_medium=residencial_tvpre',
    query: {
      'utm_source': 'crosslanding',
      'utm_medium': 'residencial_tvpre'
    },
    origem: 'webcrossbannerslanding'
  },

  {
    url: '/promo8?utm_source=promo8&utm_medium=bltvencido_ofertakit',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'bltvencido_ofertakit'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=bltvencido_ofertakit&utm_campaign=emailmkt',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'bltvencido_ofertakit',
      'utm_campaign': 'emailmkt'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=bltvencido_ofertakit&utm_campaign=sms',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'bltvencido_ofertakit',
      'utm_campaign': 'sms'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=bltvencido_ofertakit&utm_campaign=emailmkt_carrinho',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'bltvencido_ofertakit',
      'utm_campaign': 'emailmkt_carrinho'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=bltvencido_ofertakit&utm_campaign=sms_carrinho',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'bltvencido_ofertakit',
      'utm_campaign': 'sms_carrinho'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=carrinho_ofertakit',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'carrinho_ofertakit'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=carrinho_ofertakit&utm_campaign=emailmkt',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'carrinho_ofertakit',
      'utm_campaign': 'emailmkt'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=carrinho_ofertakit&utm_campaign=sms',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'carrinho_ofertakit',
      'utm_campaign': 'sms'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=carrinho_ofertakit&utm_campaign=emailmkt_carrinho',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'carrinho_ofertakit',
      'utm_campaign': 'emailmkt_carrinho'
    }
  },
  {
    url: '/promo8?utm_source=promo8&utm_medium=carrinho_ofertakit&utm_campaign=sms_carrinho',
    origem: 'webtvpreofertakit',
    query: {
      'utm_source': 'promo8',
      'utm_medium': 'carrinho_ofertakit',
      'utm_campaign': 'sms_carrinho'
    }
  },

  {
    url: '/hd?utm_source=stefanini',
    origem: 'webclarotvprestefanini',
    query: {
      'utm_source': 'stefanini'
    }
  },
  {
    url: '/hd?utm_source=stefanini&utm_campaign=sms_carrinho',
    origem: 'webclarotvprestefanini',
    query: {
      'utm_source': 'stefanini',
      'utm_campaign': 'sms_carrinho'
    }
  },
  {
    url: '/hd?utm_source=stefanini&utm_campaign=email_carrinho',
    origem: 'webclarotvprestefanini',
    query: {
      'utm_source': 'stefanini',
      'utm_campaign': 'email_carrinho'
    }
  },

  {
    url: '/hd?utm_source=pagbank',
    origem: 'webclaropagbank',
    query: {
      'utm_source': 'pagbank'
    }
  },

  {
    url: '/hd?utm_source=stefanini&utm_campaign=email_carrinho',
    origem: 'webclarotvprestefaninicarrinho',
    query: {
      'utm_source': 'stefanini',
      'utm_campaign': 'email_carrinho'
    }
  },
  {
    url: '/hd?utm_source=stefanini&utm_campaign=sms_carrinho',
    origem: 'webclarotvprestefaninicarrinho',
    query: {
      'utm_source': 'stefanini',
      'utm_campaign': 'sms_carrinho'
    }
  },
  {
    url: `/?utm_source=googleshopping&utm_medium=ads`,
    origem: 'webtvpregoogleshopping',
    query: {
      'utm_source': 'googleshopping',
      'utm_medium': 'ads'
    }
  },
  {
    url: '/?utm_source=selectra',
    query: {
      'utm_source': 'selectra'
    },
    origem: 'webclarotvpreselectra'
  },
  {
    url: '/?utm_source=vemqda',
    query: {
      'utm_source': 'vemqda'
    },
    origem: 'webclarotvprevemqda'
  }
]
