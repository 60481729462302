const RastreamentoUsuario = ({
  CacheRastreamento
}) => (data) => {
  if (data) CacheRastreamento = data
  return CacheRastreamento
}

export {
  RastreamentoUsuario
}
