import api from '@/plugins/axios/api'
import find from '@/plugins/mixins/keyfind'
import { afilioPurchasePixel, meuCupomPurchasePixel } from '@/plugins/mixins/pixelPurchase/MixinPixelPurchaseVemqda'

// eslint-disable-next-line no-unused-vars
const LOG = (...v) => window.log(...v, 'app')

function GetReturnUIMessage (id) {
  const mapMessages = require('@/database/pagamento/callback/messages')

  if (id && mapMessages.filter(i => i.id === id)) {
    return mapMessages.filter(i => i.id === id)[0]
  } else {
    return mapMessages.filter(i => i.id === 'error')[0]
  }
}

const TvpreActionPayloadVerificaEmail = ({ commit }, param) => {
  commit('TvpreMutationPayloadVerificaEmail', {
    enviado: param.enviado,
    confirmado: param.confirmado
  })
}

const ActionPayloadSalvaClearSaleSessionId = ({ commit }, param) => {
  commit('MutationPayloadSalvaClearSaleSessionId', param)
}

const ActionPayloadSalvaPedidoPagamentoUUID = ({ commit }, uuid) => {
  commit('MutationPayloadSalvaPedidoPagamentoUUID', uuid)
}

const DataLayer = ({ state }, id) => {
  const CONSUMER_DATA = state.payload.consumidor
  const CONSUMER_NAME = CONSUMER_DATA.nome.split(' ')
  const CONSUMER_ADDRESS = CONSUMER_DATA.endereco

  window.dataLayer.push({
    'event': 'conversion',
    'eventCategory': 'claro:tv-pre',
    'eventAction': 'enhanced-ecommerce',
    'eventLabel': `meta:conversion:${id}`,
    'userProvidedData.email': CONSUMER_DATA.email,
    'userProvidedData.phone_number': `55${CONSUMER_DATA.telefone.contato}`,
    'userProvidedData.address.first_name': CONSUMER_NAME.slice(0, 1).join(' '),
    'userProvidedData.address.last_name': CONSUMER_NAME.slice(1).join(' '),
    'userProvidedData.address.street': CONSUMER_ADDRESS.logradouro,
    'userProvidedData.address.city': CONSUMER_ADDRESS.uf,
    'userProvidedData.address.region': CONSUMER_ADDRESS.cidade,
    'userProvidedData.address.postal_code': CONSUMER_ADDRESS.cep,
    'userProvidedData.address.country': 'BR'
  })
}

const Atendente = ({ commit }, param) => {
  commit('ATENDENTE', {
    atendente: {
      vertex: param.atendentetoken
    }
  })
}

const Campaign = ({ commit, dispatch }, query) => {
  const analytics = {
    source: query.parametros.utm_source || '',
    medium: query.parametros.utm_medium || '',
    campaign: query.parametros.utm_campaign || '',
    params: {}
  }

  try {
    Object.keys(query.parametros).forEach(key => {
      if (key && (!/^utm_(campaign|medium|source)$/.test(key))) {
        analytics.params = {
          ...analytics.params,
          [key]: query.parametros[key]
        }
      }
    })

    analytics.params = {
      href: window.location.href,
      referrer: window.location.href === document.referrer ? 'self' : document.referrer,
      ...analytics.params
    }
  } catch {
    analytics.params = {
      search: window.location.search
    }
  } finally {
    if ((query && query.origem) && (/vemqda/).test(String(query.origem || ''))) {
      dispatch('ActionPayloadCampanhaVemqda', query.parametros)
    }
    commit('CAMPAIGN', {
      'origem': query.origem,
      'segmento': query.segmento,
      'analytics': analytics
    })
  }
}

const OpenPagamento = ({ commit }, param) => {
  commit('ADDCART', JSON.parse(param).pedido.produto)

  commit('Modules/DIALOGS', {
    'key': 'form',
    'toggle': true
  }, { root: true })

  commit('Modules/STEP',
    'pagamento'
  , { root: true })
}

const OpenOferta = ({ commit }, param) => {
  commit('ADDCART', param)

  commit('Modules/DIALOGS', {
    'key': 'form',
    'toggle': true
  }, { root: true })

  commit('Modules/STEP',
    1
  , { root: true })
}

const UpdateCacheBoleto = ({ commit }, param) => {
  commit('Modules/UPDATECACHEBOLETO', {
    param
  }, { root: true })
}

const UpdatePayload = ({ commit }, param) => {
  commit('UPDATEID', {
    '_id': param._id
  })
}

const SaveInput = ({ commit }, param) => {
  if (param.input) {
    commit('SAVEINPUT', {
      name: param.name,
      value: param.value
    })
  }

  if (param.step === 1) {
    commit('STEPCONSUMIDOR', {
      'cpf': param.cpf,
      'email': param.email,
      'contato': param.contato
    })
  }

  if (param.step === 2) {
    commit('STEPDADOSPESSOAIS', {
      'nome': param.nome,
      'nascimento': param.nascimento
    })
  }

  if (param.step === 'documento') {
    commit('STEPDOCUMENTO', {
      'numero': param.numero,
      'emissor': param.emissor,
      'uf': param.uf
    })
  }

  if (param.step === 'endereco') {
    commit('STEPENDERECO', {
      'cep': param.cep,
      'uf': param.uf,
      'cidade': param.cidade,
      'logradouro': param.logradouro,
      'numero': param.numero,
      'bairro': param.bairro,
      'complemento': param.complemento,
      'referencia': param.referencia
    })
  }

  if (param.step === 'contrato') {
    commit('STEPCONTRATO', {
      'servico': param.servico,
      'parcelas': param.parcelas
    })
  }
}

const GetOrder = ({ getters, commit, dispatch }, param) => {
  return new Promise((resolve) => {
    let formaPagamento = getters.PayloadGetterGetState('pedido').pagamento.formaPagamento
    let quebraRepeticao = /boleto/.test(formaPagamento) ? 60 : (/pix/.test(formaPagamento) ? 120 : 20)
    let intervaloRepeticao = /boleto/.test(formaPagamento) ? 3000 : (/pix/.test(formaPagamento) ? 5000 : 2000)
    const DELAY_REPETICAO = /boleto/.test(formaPagamento) ? 7000 : (/pix/.test(formaPagamento) ? 10000 : 3000)
    let ativaRepeticao
    let quantidadeRepeticao = 0
    let statusPedidoRecebido
    let statusPedidoSalvo

    function startInterval () {
      LOG('Vou repetir a função com', intervaloRepeticao, DELAY_REPETICAO)
      setTimeout(() => {
        ativaRepeticao = setInterval(order, intervaloRepeticao)
      }, DELAY_REPETICAO)
    }

    function incrementtesterror (e, id) {
      if (quantidadeRepeticao >= quebraRepeticao) {
        stopAndReturnStep({
          id: id,
          message: GetReturnUIMessage(e).message
        })
      }
      quantidadeRepeticao++
    }

    function stopAndReturnStep (e) {
      if (e.order) {
        resolve({
          error: e.error,
          order: e.order
        })
      } else {
        resolve({
          error: e
        })
      }
      clearInterval(ativaRepeticao)
    }

    function order () {
      api.get(`order/payment/callback/${param}`)
        .then(response => {
          statusPedidoRecebido = response.data.pedido.pagamento.status
          formaPagamento = response.data.pedido.pagamento.formaPagamento
          LOG('dados recebidos em src/plugins/store/payload/actions.js - GetOrder - order')
          LOG(response.data)
          if (!find.methods.keyFind(response.data, '_id')) throw Error('pedido-invalido')
          LOG('statusPedidoRecebido', statusPedidoRecebido)

          if (statusPedidoRecebido !== statusPedidoSalvo) {
            LOG('vou salvar os dados recebidos no order')
            commit('UPDATEPAYLOAD', response.data)
            statusPedidoSalvo = statusPedidoRecebido
          }
          if (find.methods.keyFind(response.data, 'oportunidade')) {
            LOG('Oportunidade recebida', response.data.oportunidade)
            if (
              !getters.PayloadGetterGetState('oportunidade') ||
              (getters.PayloadGetterGetState('oportunidade') && !getters.PayloadGetterGetState('oportunidade').id)
            ) {
              commit('MutationUpdateOportunidadePayload', response.data.oportunidade)
            }
            LOG('Oportunidade no Payload', getters.PayloadGetterGetState('oportunidade'))
          }

          if (!find.methods.keyFind(response.data, 'pagamento')) return incrementtesterror('pagamento-nao-efetuado', 'negado')
          if (!response.data.pedido.pagamento.transacao.retorno.length) return incrementtesterror('pagamento-nao-encontrado', 'negado')

          let lastTransacaoRetorno = response.data.pedido.pagamento.transacao.retorno[response.data.pedido.pagamento.transacao.retorno.length - 1]

          let catchMessage
          if (statusPedidoRecebido === 'negado') catchMessage = GetReturnUIMessage('pagamento-nao-efetuado').message
          if (statusPedidoRecebido === 'pendente') catchMessage = GetReturnUIMessage('pedido-em-processamento').message

          try {
            LOG('Repetir até', quebraRepeticao, quantidadeRepeticao, (quebraRepeticao - quantidadeRepeticao), intervaloRepeticao)

            if (statusPedidoRecebido === 'aprovado') {
              if (!find.methods.keyFind(response.data, 'oportunidade')) {
                LOG('Pagamento aprovado mas a oportunidade não existe, vamos tentar outra vez')
                incrementtesterror('pedido-em-processamento', 'pendente')
                return
              }

              if ('oportunidade' in response.data && !!response.data.oportunidade.id) {
                LOG('Disparo do pixel purchase se pagamento for aprovado')
                afilioPurchasePixel(response.data)
                meuCupomPurchasePixel(response.data)
              }

              LOG('Pagamento aprovado, vamos seguir em frente')

              dispatch('Payload/DataLayer',
                response.data.pedido.produto[0].id
              , { root: true })

              commit('Modules/CACHEPAGAMENTO', {
                'pedido': response.data.pedido
              }, {
                root: true
              })

              resolve({ success: response.data })
              clearInterval(ativaRepeticao)
              return
            }

            if (statusPedidoRecebido === 'negado') {
              LOG('O pagamento foi negado, volte uma casa')
              return stopAndReturnStep({
                id: statusPedidoRecebido,
                message: 'Não foi possível concluir sua compra através da forma de pagamento escolhida.',
                pedido: {
                  pagamento: {
                    formaPagamento: formaPagamento
                  }
                }
              })
            }

            if (statusPedidoRecebido === 'pendente') {
              if (!find.methods.keyFind(response.data, 'oportunidade') || /pix/.test(formaPagamento)) {
                if (/pix/.test(formaPagamento)) {
                  LOG('Verificando pagamento do PIX')
                } else {
                  LOG('Pagamento pendente mas a oportunidade não existe, vamos tentar outra vez')
                }
                incrementtesterror('pedido-em-processamento', statusPedidoRecebido)
                return
              }

              if (/cartaodecredito/.test(formaPagamento)) {
                LOG('Em caso de pagamento com cartão e pendente, tente por mais', quebraRepeticao, 'segundos')
                // intervaloRepeticao = 3000
                // clearInterval(ativaRepeticao)
                // startInterval()
                incrementtesterror('pedido-em-processamento', statusPedidoRecebido)
                return
              }

              LOG('Pagamento pendente, vamos seguir em frente e informar sobre o processamento do pagamento')

              dispatch('Payload/DataLayer',
                response.data.pedido.produto[0].id
              , { root: true })

              commit('Modules/CACHEPAGAMENTO', {
                'pedido': response.data.pedido
              }, {
                root: true
              })

              if (/boleto/.test(formaPagamento)) {
                catchMessage = 'Aguardando pagamento do Boleto.'
              } else {
                catchMessage = 'Seu pagamento está em análise!'
              }

              return stopAndReturnStep({
                order: response.data.oportunidade.id,
                error: {
                  id: statusPedidoRecebido,
                  message: catchMessage
                }
              })
            }
          } catch (error) {
            LOG('error on', __dirname, error)

            if (/^15$/.test(lastTransacaoRetorno.statusTransacao)) {
              return stopAndReturnStep({
                order: response.data.oportunidade.id,
                error: {
                  id: statusPedidoRecebido,
                  message: catchMessage
                }
              })
            }

            switch (statusPedidoRecebido) {
              case 'negado':
                return stopAndReturnStep({
                  id: statusPedidoRecebido,
                  message: catchMessage
                })
              default:
                return stopAndReturnStep({
                  order: response.data.oportunidade.id,
                  error: {
                    id: statusPedidoRecebido,
                    message: catchMessage
                  }
                })
            }
          }
        }).catch(error => {
          LOG('error on', __dirname, error)
          LOG(error.message)
          LOG(error.request)
          LOG(error.response)

          LOG({
            order: getters.PayloadGetterGetState('oportunidade') ? getters.PayloadGetterGetState('oportunidade').id : '',
            error: {
              id: statusPedidoRecebido,
              message: 'Seu pagamento está em análise!'
            }
          })

          switch (true) {
            case /429/i.test(error.request.status || error.response.status):
              return stopAndReturnStep({
                order: getters.PayloadGetterGetState('oportunidade') ? getters.PayloadGetterGetState('oportunidade').id : '',
                error: {
                  id: statusPedidoRecebido,
                  message: 'Seu pagamento está em análise!'
                }
              })
          }

          LOG({
            id: 'negado',
            message: 'Tivemos um erro no processamento do pagamento, por favor, tente novamente!'
          })

          return stopAndReturnStep({
            id: 'negado',
            message: 'Tivemos um erro no processamento do pagamento, por favor, tente novamente!'
          })
        })
    }

    startInterval()
  })
}

const ActionPayloaGerenciaPontoAdicional = ({ commit }, param) => {
  commit('MutationPayloadGerenciaPontoAdicional', param)
}

const ActionClearAddressPayload = ({ commit }) => {
  commit('MutationClearAddressPayload', {
      'cep': '',
      'uf': '',
      'cidade': '',
      'logradouro': '',
      'numero': '',
      'bairro': '',
      'complemento': '',
      'referencia': ''
  })
}

const ActionPayloadCampanhaVemqda = ({ commit }, data) => {
  commit('MutationPayloadCampanhaVemqda', {
    promo: data.promo,
    token: data.token,
    retail: data.retail,
    id: data.id
  })
}

export {
  TvpreActionPayloadVerificaEmail,
  ActionPayloadSalvaClearSaleSessionId,
  ActionPayloadSalvaPedidoPagamentoUUID,
  DataLayer,
  Atendente,
  Campaign,
  UpdateCacheBoleto,
  UpdatePayload,
  OpenPagamento,
  OpenOferta,
  SaveInput,
  GetOrder,
  ActionPayloaGerenciaPontoAdicional,
  ActionClearAddressPayload,
  ActionPayloadCampanhaVemqda
}
