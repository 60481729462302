export default {
  ui: {
    welcome: true,

    protocolo: {
      success: false,
      error: false
    },

    loading: {}
  }
}
