const moduleCache = () => {
  return {
    rastreamentoUsuario: {
      payload: {
        source: ''
      },
      location: {
        cidade: '',
        estado: ''
      },
      geolocation: {}
    },
    notLocation: false,
    planSelected: {},
    listaDePedidosUsuario: [],
    dialogs: {
      dialogDDDError: false,
      paymentError: false,
      dddError: false,
      waitingPaymentStatus: false,
      apiError: false,
      dialogEsimError: false,
      dialogDeliveryCepError: false
    }
  }
}

const state = moduleCache()

const getters = {
  CacheRastreamentoUsuario: ({ rastreamentoUsuario }) => (data) => {
    if (data) {
      rastreamentoUsuario.geolocation = data
    }

    return rastreamentoUsuario.geolocation
  },
  CacheRastreamentoUsuarioPayloadSource: ({ rastreamentoUsuario }) => () => {
    return rastreamentoUsuario.payload.source
  },
  CachePlanSelected: ({ planSelected }) => {
    return planSelected
  },
  CacheListaDePedidosUsuario: ({ listaDePedidosUsuario }) => () => {
    return listaDePedidosUsuario
  },
  CacheDialogDDDError: ({ dialogs }) => () => {
    return dialogs.dialogDDDError
  },
  CachePaymentError: ({ dialogs }) => () => {
    return dialogs.paymentError
  },
  CacheDialogs: (payload) => (path) => {
    if (path) {
      const getNestedProperty = (payload, path) => {
        return path.split('.').reduce((acc, key) => acc[key], payload)
      }

      return getNestedProperty(payload, path)
    }
    return payload
  }
}

const actions = {
  ActionRastreamentoUsuarioPayloadSource: ({ commit }, { source }) => {
    commit('MutationRastreamentoPayloadSource', source)
    sessionStorage.setItem('payload.source', source)
  },
  ActionCacheRastreamentoUsuarioLocation ({ commit }, { cidade, estado }) {
    commit('MutationCacheRastreamentoUsuarioLocation', { cidade, estado })
    sessionStorage.setItem('payload.consumidor.endereco', JSON.stringify({ 'cidade': cidade, 'estado': estado }))
  },
  ActionNotLocation ({ commit }, verify) {
    commit('MutationNotLocation', verify)
  },
  ActionPlanSelected ({ commit }, plan) {
    commit('MutationPlanSelected', plan)
  },
  ActionListaDePedidosUsuario ({ commit }, pedidos) {
    commit('MutationListaDePedidosUsuario', pedidos)
  },
  ActionDialogDDDError ({ commit }, value) {
    commit('MutationDialogDDDError', value)
  },
  ActionPaymentError ({ commit }, value) {
    commit('MutationPaymentError', value)
  },
  ActionDialogs: ({ commit }, payload) => {
    commit('MutationDialogs', payload)
  }
}

const mutations = {
  MutationRastreamentoPayloadSource: ({ rastreamentoUsuario }, { source }) => {
    rastreamentoUsuario.payload.source = source
  },
  MutationCacheRastreamentoUsuarioLocation ({ rastreamentoUsuario }, { cidade, estado }) {
    rastreamentoUsuario.location = {
      cidade: cidade,
      estado: estado
    }
  },
  MutationNotLocation (state, verify) {
    state.notLocation = verify
  },
  MutationPlanSelected (state, plan) {
    state.planSelected = plan
  },
  MutationListaDePedidosUsuario (state, pedidos) {
    state.listaDePedidosUsuario = pedidos
  },
  MutationDialogDDDError (state, value) {
    state.dialogs.dialogDDDError = value
  },
  MutationPaymentError (state, value) {
    state.dialogs.paymentError = value
  },
  MutationDialogs: (state, payload) => {
    const setNestedProperty = (state, path, value) => {
      const PATH_ARRAY = path.split('.')
      const LAST_KEY = PATH_ARRAY.pop()

      PATH_ARRAY.reduce((acc, key) => {
        if (!acc[key]) {
          acc[key] = {}
        }
        return acc[key]
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      }, state)[LAST_KEY] = value
    }

    setNestedProperty(state, payload.path, payload.value)
  }
}

const cacheStoreModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export {
  cacheStoreModule
}
