let cobrand = [
  'ricardoeletro',
  'pernambucanas',
  'muffato',
  'carrefour',
  'lebes',
  'boravender',
  'cea',
  'casasbahia',
  'pontofrio',
  'colombo',
  'novomundo',
  'claro'
]

export default {
  env: {
    brand: 'Claro',
    cobrand: cobrand,
    color: 'white',
    dark: false,
    description: {
      default: 'O melhor plano para você está aqui.',
      share: 'Tenha um plano controle de 8GB para falar ilimitado por apenas 49,99'
    },
    process: process.env.NODE_ENV,
    development: process.env.NODE_ENV === 'development',
    embed: undefined
  },

  productPromo: {
    hasPromo: false,
    promo: {
      name: '',
      product: ''
    }
  },

  windowSize: {
    x: 0,
    y: 0,
    miniProfile: 320,
    lowProfile: 420,
    mediunProfile: 680,
    tabletProfile: 768,
    proProfile: 996,
    laptopProfile: 1024,
    middleProfile: 1080,
    laptopProfileH: 648,
    fullhdProfile: 1400
  },

  dialogs: {
    popup: false,
    form: false,
    states: false,
    loading: false,
    telbox: false,
    regulamento: false,
    consulta: false,
    newsletter: false
  },

  cache: {
    servico: {
      plano: {
        nome: undefined,
        preco: undefined,
        franquia: undefined
      }
    },

    pagamento: false,
    disable: {
      payload: false
    },
    consumidor: {
      endereco: {
        uf: undefined,
        city: undefined
      },
      verify: {
        sms: undefined
      },
      servico: {
        ddd: undefined
      }
    }
  },

  tel: {
    // sp: {
    //   state: 'São Paulo',
    //   tel: '(11) 3090-2884'
    // },
    rj: {
      state: 'Rio de Janeiro',
      tel: ''
    }
  },

  formData: {
    openedStep: {
      first: 1,
      actual: 0,
      last: 5,
      breadCrumbs: [
        {
          step: 1,
          class: 'first',
          background: 'breadcrumb-first'
        },
        {
          step: 2,
          class: 'second',
          background: 'breadcrumb-second'
        },
        {
          step: 3,
          class: 'third',
          background: 'breadcrumb-third'
        },
        {
          step: 4,
          class: 'fourt',
          background: 'yellow-modal'
        }
      ],

      error: []
    },

    pagamento: {
      dataVencimento: [
        '01', '15', '20'
      ],
      tipoFatura: {
        name: [
          'Digital',
          'Correios'
        ],
        friendly: [
          'Digital (E-mail)',
          'Correios (Boleto)'
        ]
      },

      bonus: {
        portabilidade: {
          active: true,
          validate: true,
          estados: [ 31, 32, 33, 34, 35, 37, 38, 71 ]
        },
        debitoAutomaticoFidelity: {
          active: true,
          valor: 5
        }
      },

      disables: {
        planMix: {
          active: false,
          validate: undefined,
          value: 0,
          uf: [],
          ddd: []
        }
      }
    },

    tipoServico: {
      name: [
        'migracao',
        'portabilidade',
        'ativacao'
      ],
      disable: [
        [],
        [ 'express' ],
        [ 'express', 'controle' ]
      ],
      friendly: [
        'Migração',
        'Portabilidade',
        'Ativação'
      ],
      phrase: [
        'Sou cliente Claro e quero mudar de plano',
        'Sou de outra operadora e quero mudar para a Claro',
        'Quero uma nova linha com um número Claro'
      ]
    }
  },

  texts: {
    faq: [
      {
        title: 'O que é o Claro controle?',
        paragraph: [
          'O plano controle permite você saber exatamente quanto vai gastar no final do mês.',
          '<br>Confira os principais benefícios:',
          '• Mais internet com Whatsapp ilimitado até o final da franquia de dados;',
          '• Minutos ilimitados¹ para outras operadoras em todos os planos;',
          '• Aplicativos digitais já inclusos no plano².',
          '<br>¹ Minutos ilimitados local ou local e longa distância (utilizando o 21) variam de acordo com o plano;',
          '² Aplicativos variam de acordo com o plano.'
        ]
      },
      {
        title: 'Posso realizar ligações ilimitadas?',
        paragraph: [
          'No plano controle 2,5GB você possui: Ligações ilimitadas locais.',
          '<br>No plano controle 3,5GB ou 8GB você possui: Ligações locais e longa distância para Claro de todo o Brasil, utilizando o 21.'
        ]
      },
      {
        title: 'Já sou cliente Claro, preciso mudar meu número?',
        paragraph: [
          'Não. Sendo cliente Claro você pode efetuar a mudança para o plano controle na sua linha atual, mantendo seu número. Caso seja cliente de outra operadora, nós fazemos a portabilidade para você.'
        ]
      },
      {
        title: 'O que eu preciso para ter a velocidade do 4.5G?',
        paragraph: [
          'Para aproveitar todos os benefícios do 4.5G da Claro é necessário um aparelho compatível com o 4.5G e estar na área de cobertura da nova tecnologia. Confira no site as cidades onde o 4.5G já está disponível.',
          '<br>Se você ainda não tem um smartphone compatível, não se preocupe. O serviço melhora para todos os aparelhos compatíveis com o 4G também. É como construir uma nova pista em uma estrada. Quem está habilitado usa a nova e isso faz com que o movimento diminua na faixa que existe, melhorando para todos.'
        ]
      },
      {
        title: 'Sou cliente de outra operadora, posso solicitar o plano?',
        paragraph: [
          'Sim. Você pode solicitar a portabilidade do seu número atual agora mesmo. Nós fazemos todo o processo para você, sem a necessidade de entrar em contato com sua operadora atual. Enviamos um chip Claro para seu endereço de entrega sem nenhum custo. Para garantir que sua portabilidade seja concluída, é necessário que o número informado esteja ativo e a solicitação deve ser feita pelo titular da linha.'
        ]
      },
      {
        title: 'Quais são os pacotes de internet que posso adquirir caso a franquia acabe?',
        paragraph: [
          'Temos pacotes de acordo com o perfil de consumo de cada cliente. Recomendamos a compra dos pacotes adicionais de dados que são válidos até o final do ciclo da sua franquia: 300MB, 500MB e 1GB. Dessa forma, o pacote adicional será cobrado apenas na próxima fatura.',
          '<br>Além disso, temos também as diárias de internet (100MB e 200MB). Para compra das diárias de internet é necessário realizar uma recarga. Para verificar todos os pacotes disponíveis, <a href="https://auth.netcombo.com.br/web/login.html?client_id=MinhaClaroDIG&redirect_uri=https://www.meuplanoclaro.com.br" target="_blank" class="brand-dark--text" >acesse</a> a Minha Claro.'
        ]
      }
    ]
  },

  images: {
    brand:
      {
        footer: require('@/assets/images/logos/logo-claro-branco.png'),
        icon: require('@/assets/images/logos/logo-claro-red-thin-concept.png'),
        white: require('@/assets/images/logos/logo-claro-branco-thin-concept.png'),
        loading: require('@/assets/images/logos/logo-claro-concept-icon-loading.gif')
      },
    banner:
      {},
    popup:
      {},
    offers: []
  },

  response: {}
}
