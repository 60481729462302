module.exports = [
  {
    id: 'id-not-found',
    message: 'Desculpe, este protocolo não foi localizado.'
  },

  {
    id: 'buscando-protocolo',
    message: `
    Por favor, aguarde um instante<br>
    enquanto buscamos as informações de pagamento.
    `
  },

  {
    id: 'error',
    message: `
    Desculpe, não conseguimos processar sua solicitação.<br>
    Por favor, tente novamente!
    `
  }
]
