import Vue from 'vue'
import '@/prototype'
import IdleVue from 'idle-vue'
import { VueMaskDirective } from 'v-mask'
import store from '@/plugins/store'
import router from '@/plugins/router'
import debug from 'debug'
import '@/plugins/vuetify'
import App from '@/App'

debug.enable(window.env('development') && 'root')
const log = debug('root')
log('---- Vertex Claro Tv Pré Started ----')

Vue.directive('mask', VueMaskDirective)

Vue.use(IdleVue, {
  eventEmitter: new Vue(),
  idleTime: 20000
})

Vue.config.productionTip = false

new Vue({
  store,
  router: router(),
  render: h => h(App)
}).$mount('#app')
