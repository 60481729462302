import api from '@/plugins/axios/api'
import * as u from 'underscore'

const getDefaultStoreState = () => {
  return {
    url: ((process.env.VUE_APP_NODE_ENV !== 'production') ? 'https://claro-controle-parceiros.stg.m4u.com.br' : 'https://claro-controle-parceiros.m4u.com.br'),
    uuid: undefined,
    plans: undefined,
    random: undefined,
    token: undefined,
    cardKey: undefined,
    cvv: undefined
  }
}

const M4UState = getDefaultStoreState()
const M4UStore = {
  namespaced: true,
  state: M4UState,
  mutations: {
    resetM4UState (state) {
      Object.assign(state, getDefaultStoreState())
    },
    setCardKey (state, data) {
      state.cardKey = data
    },
    setCvv (state, data) {
      state.cvv = data
    },
    setUUID (state, data) {
      state.uuid = data
    },
    setAuthKeys (state, data) {
      state.random = data.random
      state.token = data.token
    },
    setPlans (state, data) {
      state.plans = data.plans
    }
  },
  actions: {
    m4uPlansByDDD ({
      state,
      commit,
      rootState
    }, data) {
      return new Promise((resolve, reject) => {
        api.get('/m4u/offers', {
            params: {
              msisdn: rootState.Modules.payload.consumidor.telefone.servico
            }
          })
          .then((response) => {
            commit('setPlans', { plans: response.data.plans })
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    m4uCheckPlanEligibility ({ state }, data) {
      if (u.some(state.plans, (p) => {
        return p.code === data.code
      })) {} else throw new Error({ msg: 'Plan ID' + data.code + ' not found at M4U plans' })
    },
    m4uVerifyVendor ({
      state,
      commit,
      rootState
    }, data) {
      return new Promise((resolve, reject) => {
        api.get('/m4u/auth/vendor')
          .then((response) => {
            commit('setUUID', response.data.uuid)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    m4uVerifyClient ({
      state,
      commit,
      rootState
    }, data) {
      return new Promise((resolve, reject) => {
        api.get('/m4u/auth/client', {
            params: {
              cpf: rootState.Modules.payload.consumidor.cpf,
              msisdn: rootState.Modules.payload.consumidor.telefone.servico,
              planId: rootState.Modules.payload.servico.plano.id,
              uuid: state.uuid
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    m4uVerifyClientToken ({
      state,
      commit,
      rootState
    }, data) {
      return new Promise((resolve, reject) => {
        api.get('/m4u/auth/client/token', {
            params: {
              token: data,
              cpf: rootState.Modules.payload.consumidor.cpf,
              msisdn: rootState.Modules.payload.consumidor.telefone.servico,
              planId: rootState.Modules.payload.servico.plano.id,
              uuid: state.uuid
            }
          })
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    m4uGetIframeAuthToken ({
      state,
      commit,
      rootState
    }) {
      return new Promise((resolve, reject) => {
        api.get('/m4u/auth-code', {
            params: {
              cpf: rootState.Modules.payload.consumidor.cpf
            }
          })
          .then((response) => {
            commit('setAuthKeys', response.data)
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    m4uSalesConfirm ({
      state,
      commit,
      rootState
    }) {
      return new Promise((resolve, reject) => {
        api.post('/m4u/sale/confirm', {
          params: {
            payload: rootState.Modules.payload,
            cpf: rootState.Modules.payload.consumidor.cpf,
            msisdn: rootState.Modules.payload.consumidor.telefone.servico,
            uuid: state.uuid,
            key: state.cardKey,
            cvv: state.cvv,
            planId: rootState.Modules.payload.servico.plano.id

          }
        })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
      })
    }
  },
  getters: {
    getAuthKeys (state) {
      return {
        random: state.random,
        token: state.token
      }
    },
    getIframeURL (state) {
      return state.url
    }
  }

}

export {
  M4UStore
}
