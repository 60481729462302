export default {
  payload: {
    'session': {
      'token': '',
      'vendas': ''
    },

    'lojaPropria': {
      'codigo': '',
      'nome': '',
      'regional': '',
      'nomeLoja': ''
    }
  }
}
