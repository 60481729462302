const session = require('@/plugins/storage/session').default
const encryptation = require('@/plugins/storage/token').default

const SaveSeller = ({ commit, dispatch }, param) => {
  commit('SAVESELLER', {
    name: param.name,
    value: param.value
  })

  commit('Payload/SAVEINPUT', {
    name: param.name,
    value: param.value
  }, { root: true })

  dispatch('LojaPropria/SaveToken', { pdv: 'lojaPropria' }, { root: true })
}

const GetSeller = ({ state }, param) => {
  const sessionSeller = encryptation.decrypt(session.getObject(param.pdv)) || false

  return sessionSeller && state.payload
}

const SaveToken = ({ state, commit }, param) => {
  if (Object.values(state.payload[param.pdv]).every(key => key)) {
    session.setObject(param.pdv, encryptation.encrypt(state.payload[param.pdv]))

    commit('SAVESELLER', {
      name: 'sessionToken',
      value: encryptation.encrypt(state.payload[param.pdv])
    })
  }
}

const GetToken = ({ dispatch }, param) => {
  const sessionSeller = encryptation.decrypt(session.getObject(param.pdv)) || false

  if (sessionSeller) {
    dispatch('LojaPropria/SaveSeller', {
      name: 'sessionSeller',
      value: sessionSeller
    }, { root: true })
  }

  return !!sessionSeller
}

const LogoutSeller = ({ dispatch }, param) => {
  dispatch('LojaPropria/SaveSeller', {
    name: 'sessionToken',
    value: ''
  }, { root: true })

  dispatch('LojaPropria/SaveSeller', {
    name: 'sessionVendas',
    value: ''
  }, { root: true })

  dispatch('LojaPropria/SaveSeller', {
    name: 'sessionSeller',
    value: ''
  }, { root: true })

  session.remove(param.pdv)

  window.location.reload()
}

export {
  SaveSeller,
  GetSeller,
  SaveToken,
  GetToken,
  LogoutSeller
}
