const TOGGLE = (state, param) => {
  if (param.ref === 'stateformDatapagamentobonusportabilidadevalidate') state.formData.pagamento.bonus.portabilidade.validate = param.key
}

const ROUTEPROMO = (state, param) => {
  state.productPromo = { ...param }
}

const STEP = (state, step) => {
  state.formData.openedStep.actual = step
}

const DIALOGS = (state, param) => {
  state.dialogs[param.key] = param.toggle
}

const LOADING = (state, value) => {
  state.dialogs.loading = value
}

const WINDOWSIZE = (state, param) => {
  state.windowSize.x = param.width
  state.windowSize.y = param.height
}

const CACHE = (state, param) => {
  if (param.key === 'verifysms') state.cache.consumidor.verify.sms = param.value
  if (param.key === 'disablepayload') state.cache.disable.payload = param.value
  if (param.key === 'pagamento') state.cache.pagamento = param.toggle
}

const CACHEPAGAMENTO = ({
  cache
}, {
  pedido
}) => {
  cache.pedido = pedido.pagamento.transacao
}

const UPDATECACHEBOLETO = ({
  cache
}, {
  param
}) => {
  cache.boleto = { ...param }
}

const FORMDATAOPENED = (state, value) => {
  state.dialogs.form = value
  value ? state.formData.openedStep.actual = 1 : state.formData.openedStep.actual = 0
  !value && (state.dialogs.loading = true)
  setTimeout(() => {
    if (!value) {
      if (/pedido/.test(location.pathname)) {
        return location.assign(`${location.origin}${location.search}`)
      } else return location.assign(location.href)
    }
  }, 1920)
}

const SAVEDDDUF = (state, param) => {
  if (param.uf) state.cache.consumidor.endereco.uf = param.uf
  if (param.ddd) {
    state.cache.consumidor.servico.ddd = param.ddd
    state.payload.servico.ddd = param.ddd
  }
}

const CHECKDDDUF = (state, param) => {
  if (param.key === 'planMix') state.formData.pagamento.disables.planMix.validate = param.value
}

const UPDATESTEP = (state, step) => {
  state.formData.openedStep.actual = step
}

const SETDOCCUSTOMER = (state, {
  dataEmissao,
  numeroDocumento,
  orgaoEmissor,
  ufEmissor
}) => {
  state.payload.consumidor.documentacao = {
    ...state.payload.consumidor.documentacao,
    numeroDocumento,
    orgaoEmissor,
    dataEmissao,
    ufEmissor
  }
}

const SETCUSTOMER = ({
  payload
}, {
  sexo,
  nome,
  filiacao,
  nascimento
}) => {
  payload.consumidor = {
    ...payload.consumidor,
    sexo,
    nome,
    filiacao,
    nascimento
  }
}

const SETADDRESSCUSTOMER = ({
  payload
}, {
  bairro,
  cep,
  cidade,
  complemento,
  logradouro,
  numero,
  uf
}) => {
  payload.consumidor.endereco = {
    ...payload.consumidor.endereco,
    bairro,
    cep,
    cidade,
    complemento,
    logradouro,
    numero,
    uf
  }
}

const PAYLOAD = (state, param) => {
  if (param.source) state.payload.source = param.source
  if (param.segmento) state.payload.segmento = param.segmento
  if (param.planType) state.payload.servico.plano.id = param.planType

  if (param.planName) state.cache.servico.plano.nome = param.planName
  if (param.planGb) state.cache.servico.plano.franquia = param.planGb
  if (param.planPrice) state.cache.servico.plano.preco = param.planPrice

  // @todo - para controle facil //
  if (state.payload.segmento === 'express') {
    if (param.planName) state.payload.servico.plano.nome = param.planName
    if (param.planGb) state.payload.servico.plano.franquia = param.planGb
    if (param.planPrice) state.payload.servico.plano.preco = param.planPrice
  }
  // @todo - para controle facil //

  if (param.key === 'servicoRegulacaoFidelidade') state.payload.servico.regulacaoFidelidade = param.planFidelity

  if (param.consumidorCpf) state.payload.consumidor.cpf = param.consumidorCpf
  if (param.consumidorEmail) state.payload.consumidor.email = param.consumidorEmail

  if (param.consumidorTelefoneContato) state.payload.consumidor.telefone.contato = param.consumidorTelefoneContato
  if (param.servicoTipoServicoName) {
    state.payload.servico.tipoServico = param.servicoTipoServicoName
    if (param.servicoTipoServicoName === state.formData.tipoServico.name[2]) state.payload.consumidor.telefone.servico = ''
  }
  if (param.consumidorTelefoneServico) state.payload.consumidor.telefone.servico = param.consumidorTelefoneServico

  if (param.consumidorName) state.payload.consumidor.nome = param.consumidorName
  if (param.consumidorFiliacao) state.payload.consumidor.filiacao = param.consumidorFiliacao
  if (param.consumidorNascimento) state.payload.consumidor.nascimento = param.consumidorNascimento

  if (param.consumidorEnderecoCep) state.payload.consumidor.endereco.cep = param.consumidorEnderecoCep
  if (param.consumidorEnderecoLogradouro) state.payload.consumidor.endereco.logradouro = param.consumidorEnderecoLogradouro
  if (param.consumidorEnderecoBairro) state.payload.consumidor.endereco.bairro = param.consumidorEnderecoBairro
  if (param.consumidorEnderecoCidade) state.payload.consumidor.endereco.cidade = param.consumidorEnderecoCidade
  if (param.consumidorEnderecoUf) state.payload.consumidor.endereco.uf = param.consumidorEnderecoUf
  if (param.consumidorEnderecoNumero) state.payload.consumidor.endereco.numero = param.consumidorEnderecoNumero
  if (param.key === 'consumidorEnderecoComplemento' || param.consumidorEnderecoComplemento) state.payload.consumidor.endereco.complemento = param.value

  if (param.servicoPagamentoDataVencimento) state.payload.servico.pagamento.dataVencimento = param.servicoPagamentoDataVencimento

  if (param.key === 'servicoPagamentoTipoFatura') {
    switch (param.servicoPagamentoTipoFatura) {
      case state.formData.pagamento.tipoFatura.friendly[0]:
        state.payload.servico.pagamento.tipoFatura = state.formData.pagamento.tipoFatura.name[0]
        break
      case state.formData.pagamento.tipoFatura.friendly[1]:
        state.payload.servico.pagamento.tipoFatura = state.formData.pagamento.tipoFatura.name[1]
        break
    }
  }

  if (param.key === 'servicoPagamentoDebitoAutomaticoUsarDebitoAutomatico') state.payload.servico.pagamento.debitoAutomatico.usarDebitoAutomatico = param.servicoPagamentoDebitoAutomaticoUsarDebitoAutomatico

  if (param.key === 'servicoPagamentoDebitoAutomaticoDadosBancarios') {
    if (state.payload.servico.pagamento.debitoAutomatico.usarDebitoAutomatico) {
      state.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco = param.servicoPagamentoDebitoAutomaticoDadosBancoBanco
      state.payload.servico.pagamento.debitoAutomatico.dadosBanco.agencia = param.servicoPagamentoDebitoAutomaticoDadosBancoAgencia
      state.payload.servico.pagamento.debitoAutomatico.dadosBanco.conta = param.servicoPagamentoDebitoAutomaticoDadosBancoConta
    } else {
      state.payload.servico.pagamento.debitoAutomatico.dadosBanco.banco = ''
      state.payload.servico.pagamento.debitoAutomatico.dadosBanco.agencia = ''
      state.payload.servico.pagamento.debitoAutomatico.dadosBanco.conta = ''
    }
  }

  if (param.key === 'servicoContratoServico') {
    state.payload.servico.ciente.contrato.servico = param.value
    state.payload.servico.regulacaoPlano = param.value
    state.payload.consumidor.session.finished = param.value
  }

  /* informações do UUID do cartão */
  if (param.key === 'uuid') {
    state.payload.servico.pagamento.cartaoDeCredito.dadosTransacao.uuid = param.uuid
    state.payload.servico.pagamento.cartaoDeCredito.usarCartaoDeCredito = param.usarCartaoDeCredito

    if (param.usarCartaoDeCredito) {
      state.payload.servico.pagamento.tipoFatura = ''
      state.payload.servico.pagamento.dataVencimento = ''
      state.payload.servico.regulacaoFidelidade = false
      state.payload.servico.pagamento.debitoAutomatico.usarDebitoAutomatico = false
    } else {
      state.payload.servico.pagamento.tipoFatura = state.formData.pagamento.tipoFatura.name[0]
      state.payload.servico.pagamento.debitoAutomatico.usarDebitoAutomatico = true
    }
  }
  /* informações do UUID do cartão */
}

const RESPONSE = (state, param) => {
  state.payload._id = param._id
  state.response = param
}

const GOTOOPENEDSTEP = (state, value) => {
  state.formData.openedStep.actual = value
}

const INCREMENTDECREMENTOPENEDSTEP = (state, value) => {
  if (value) {
    state.formData.openedStep.actual++
  } else {
    state.formData.openedStep.actual--
  }
}

const FINISHSESSION = (state, value) => {
  state.payload.consumidor.session.finished = value
}

export {
  TOGGLE,
  STEP,
  CACHE,
  CACHEPAGAMENTO,
  UPDATECACHEBOLETO,
  DIALOGS,
  SAVEDDDUF,
  CHECKDDDUF,
  LOADING,
  FORMDATAOPENED,
  WINDOWSIZE,
  PAYLOAD,
  RESPONSE,
  GOTOOPENEDSTEP,
  INCREMENTDECREMENTOPENEDSTEP,
  FINISHSESSION,
  UPDATESTEP,
  SETDOCCUSTOMER,
  SETCUSTOMER,
  SETADDRESSCUSTOMER,
  ROUTEPROMO
}
