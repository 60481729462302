const Home = () => (param) => {
  if (param && param.hasOwnProperty('path')) return location.assign(location.origin + '/' + param.path)
  return location.assign(location.href)
}

const Share = (state, getters) => (param) => {
  if (!navigator.share) return getters.Home()

  navigator.share({
    title: param && param.hasOwnProperty('title') ? param.title : state.env.brand,
    text: param && param.hasOwnProperty('text') ? param.text : state.env.description.share,
    url: param && param.hasOwnProperty('path') ? param.path : location.href
  })
}

const MaxYear = () => (param) => {
  function year (param) {
    let date = new Date().toISOString().substr(0, 10)
    let year = date.split('-')[0]
    let maxYear = parseInt(year) - param
    return maxYear + new Date().toISOString().substr(4, 6)
  }

  return year(param)
}

const MinYear = () => (param) => {
  function year (param) {
    let date = new Date().toISOString().substr(0, 10)
    let year = date.split('-')[0]
    let maxYear = parseInt(year) - param
    return maxYear + new Date().toISOString().substr(4, 6)
  }

  return year(param)
}

const OpenToday = () => (param) => {
  let date = new Date((parseInt(Math.floor(new Date().getTime() / 1000.0)) + (parseInt(86400) * param)) * 1000).toISOString().substr(0, 10)
  let day = date.split('-')[2]
  let month = date.split('-')[1]
  let year = date.split('-')[0]
  return day + '/' + month + '/' + year
}

const RoutePromo = (state) => {
  return state.productPromo
}

export {
  Home,
  Share,
  MaxYear,
  MinYear,
  OpenToday,
  RoutePromo

}
