const SAVESELLER = ({
  payload
}, {
  name,
  value
}) => {
  /** @LojaPropria */
  if (name === 'sessionToken') payload.session.token = value
  if (name === 'sessionVendas') payload.session.vendas = value

  if (name === 'lojaPropriaCodigo') payload.lojaPropria.codigo = value
  if (name === 'lojaPropriaNome') payload.lojaPropria.nome = value
  if (name === 'lojaPropriaRegional') payload.lojaPropria.regional = value
  if (name === 'lojaPropriaNomeLoja') payload.lojaPropria.nomeLoja = value

  if (name === 'sessionSeller') payload.lojaPropria = value
  /** @LojaPropria */
}

export {
  SAVESELLER
}
