const MutationPayloadSalvaClearSaleSessionId = ({
  payload
}, param) => {
  payload.pedido.pagamento = {
    ...payload.pedido.pagamento,
    clearSale: {
      sessionId: param
    }
  }
}

const MutationPayloadSalvaPedidoPagamentoUUID = ({
  payload
}, uuid) => {
  payload.pedido.pagamento = {
    ...payload.pedido.pagamento,
    uuid: uuid
  }
}

const TvpreMutationPayloadVerificaEmail = ({
  payload
}, {
  enviado,
  confirmado
}) => {
  payload.verificacaoEmail.enviado = enviado
  payload.verificacaoEmail.confirmado = confirmado
}

const ATENDENTE = ({
  payload
}, {
  atendente
}) => {
  payload.atendente.vertex = atendente.vertex
}

const PAYLOADPAGAMENTO = ({
  payload
}, {
  _id,
  origem,
  segmento,
  consumidor,
  pedido,
  oportunidade
}) => {
  payload._id = _id
  payload.origem = origem
  payload.segmento = segmento
  payload.consumidor = { ...consumidor }
  payload.pedido.ciente = { ...pedido.ciente }
  payload.pedido.produto = pedido.produto
  payload.pedido.pagamento.formaPagamento = pedido.pagamento.formaPagamento
  payload.pedido.pagamento.status = pedido.pagamento.status
  payload.pedido.postagem = pedido.postagem
  payload.pedido.valorTotal = pedido.valorTotal
  payload.oportunidade = oportunidade
}

const UPDATEID = ({
  payload
}, {
  _id
}) => {
  payload._id = _id
}

const UPDATEPAYLOAD = (state, value) => {
  state.payload = value
}

const MutationUpdateOportunidadePayload = ({
  payload
}, oportunidade) => {
  payload.oportunidade = oportunidade
}

const SAVEINPUT = ({
  payload
}, {
  name,
  value
}) => {
  if (name === 'consumidorCpf') payload.consumidor.cpf = value

  if (name === 'consumidorEmail') payload.consumidor.email = value
  if (name === 'consumidorNome') payload.consumidor.nome = value
  if (name === 'consumidorNascimento') payload.consumidor.nascimento = value

  if (name === 'consumidorTelefoneContato') payload.consumidor.telefone.contato = value

  // if (name === 'consumidorIdentidadeNumero') payload.consumidor.identidade.numero = value
  // if (name === 'consumidorIdentidadeEmissor') payload.consumidor.identidade.emissor = value
  // if (name === 'consumidorIdentidadeUf') payload.consumidor.identidade.uf = value

  if (name === 'consumidorEnderecoCep') payload.consumidor.endereco.cep = value
  if (name === 'consumidorEnderecoLogradouro') payload.consumidor.endereco.logradouro = value
  if (name === 'consumidorEnderecoCidade') payload.consumidor.endereco.cidade = value
  if (name === 'consumidorEnderecoUf') payload.consumidor.endereco.uf = value
  if (name === 'consumidorEnderecoBairro') payload.consumidor.endereco.bairro = value
  if (name === 'consumidorEnderecoNumero') payload.consumidor.endereco.numero = value
  if (name === 'consumidorEnderecoComplemento') payload.consumidor.endereco.complemento = value
  if (name === 'consumidorEnderecoReferencia') payload.consumidor.endereco.referencia = value

  if (name === 'pedidoProdutoQuantidade') payload.pedido.produto[0].quantidade = value
  if (name === 'pedidoProdutoAdicionalQuantidade') payload.pedido.produto[1].quantidade = value

  if (name === 'pedidoValor') payload.pedido.produto[0].preco = value

  if (name === 'pedidoValorTotal') payload.pedido.valorTotal = value

  if (name === 'pedidoPostagemServicoEntrega') payload.pedido.postagem.servicoEntrega = value
  if (name === 'pedidoPostagemValorEntrega') payload.pedido.postagem.valorEntrega = value

  if (name === 'pedidoPagamentoFormaPagamento') payload.pedido.pagamento.formaPagamento = value
  if (name === 'pedidoPagamentoCartaoDeCreditoParcelas') payload.pedido.pagamento.cartaoDeCredito.parcelas = value

  if (name === 'pedidoCienteInstalacao') payload.pedido.ciente.instalacao = value
  if (name === 'pedidoCienteRecarga') payload.pedido.ciente.recarga = value
  if (name === 'pedidoCienteContratoServico') payload.pedido.ciente.contrato.servico = value

  /** @LojaPropria */
  if (name === 'lojaPropriaCodigo') payload.lojaPropria.codigo = value
  if (name === 'lojaPropriaNome') payload.lojaPropria.nome = value
  if (name === 'lojaPropriaRegional') payload.lojaPropria.regional = value
  if (name === 'lojaPropriaNomeLoja') payload.lojaPropria.nomeLoja = value

  if (name === 'sessionSeller') payload.lojaPropria = value
  /** @LojaPropria */
}

const MutationPayloadRedeinovaSaveSeller = ({
  payload
}, {
  data
}) => {
  /** @RedeInova */
    payload.redeinova = {
      ...data
    }
  /** @RedeInova */
}

const CAMPAIGN = ({
  payload
}, {
  origem,
  segmento,
  analytics
}) => {
  payload.origem = origem
  payload.segmento = segmento
  payload.analytics = analytics
}

const GEOTAG = ({
  payload
}, {
  ip,
  latitude,
  longitude,
  regiao
}) => {
  payload.consumidor.geotag.ip = ip
  payload.consumidor.geotag.latitude = latitude
  payload.consumidor.geotag.longitude = longitude
  payload.consumidor.geotag.regiao = regiao
}

const ADDCART = ({
  payload
}, data) => {
  payload.pedido.produto = data
}

const STEPCONSUMIDOR = ({
  payload
}, {
  cpf,
  email,
  contato
}) => {
  payload.consumidor.cpf = cpf
  payload.consumidor.email = email
  payload.consumidor.telefone.contato = contato
}

const STEPDADOSPESSOAIS = ({
  payload
}, {
  nome,
  nascimento
}) => {
  payload.consumidor.nome = nome
  payload.consumidor.nascimento = nascimento
}

const STEPDOCUMENTO = ({
  payload
}, {
  numero,
  emissor,
  uf
}) => {
  // payload.consumidor.identidade.numero = numero
  // payload.consumidor.identidade.emissor = emissor
  // payload.consumidor.identidade.uf = uf
}

const STEPENDERECO = ({
  payload
}, {
  cep,
  uf,
  cidade,
  logradouro,
  numero,
  bairro,
  complemento,
  referencia
}) => {
  payload.consumidor.endereco.cep = cep
  payload.consumidor.endereco.uf = uf
  payload.consumidor.endereco.cidade = cidade
  payload.consumidor.endereco.logradouro = logradouro
  payload.consumidor.endereco.numero = numero
  payload.consumidor.endereco.bairro = bairro
  payload.consumidor.endereco.complemento = complemento
  payload.consumidor.endereco.referencia = referencia
}

const STEPCONTRATO = ({
  payload
}, {
  servico,
  parcelas
}) => {
  payload.pedido.ciente.contrato.servico = servico
  payload.pedido.pagamento.cartaoDeCredito.parcelas = parcelas
}

const MutationPayloadGerenciaPontoAdicional = ({
  payload
}, param) => {
  payload.pedido.produto = param
}

const MutationClearAddressPayload = ({
  payload
}, {
  cep,
  uf,
  cidade,
  logradouro,
  numero,
  bairro,
  complemento,
  referencia
}) => {
  payload.consumidor.endereco.cep = cep
  payload.consumidor.endereco.uf = uf
  payload.consumidor.endereco.cidade = cidade
  payload.consumidor.endereco.logradouro = logradouro
  payload.consumidor.endereco.numero = numero
  payload.consumidor.endereco.bairro = bairro
  payload.consumidor.endereco.complemento = complemento
  payload.consumidor.endereco.referencia = referencia
}

const MutationPayloadCampanhaVemqda = (state, data) => {
  state.payload = { ...state.payload, vemQDa: data }
}

export {
  MutationPayloadSalvaClearSaleSessionId,
  MutationPayloadSalvaPedidoPagamentoUUID,
  TvpreMutationPayloadVerificaEmail,
  ATENDENTE,
  PAYLOADPAGAMENTO,
  UPDATEID,
  UPDATEPAYLOAD,
  MutationUpdateOportunidadePayload,
  SAVEINPUT,
  MutationPayloadRedeinovaSaveSeller,
  CAMPAIGN,
  GEOTAG,
  ADDCART,
  STEPCONSUMIDOR,
  STEPDADOSPESSOAIS,
  STEPDOCUMENTO,
  STEPENDERECO,
  STEPCONTRATO,
  MutationPayloadGerenciaPontoAdicional,
  MutationClearAddressPayload,
  MutationPayloadCampanhaVemqda
}
