import Vue from 'vue'

function widthheight (el, binding) {
  /** @HowUse */
  /*
    acceptable parameters
      v-width
      v-width.min
      v-width.max
      v-height
      v-height.min
      v-height.max
  */

  /*
    acceptable values
      100   Number
      100px String
      100%  String
      100vw String
      100vh String
  */
  /** @HowUse */

  const modifiersFirst = ['padding', 'margin']

  let modifiers = binding.name

  if (Object.keys(binding.modifiers).length) {
    switch (true) {
      case modifiersFirst.includes(modifiers):
        modifiers = `${modifiers}-${Object.keys(binding.modifiers)[0]}`
        break
      default:
        modifiers = `${Object.keys(binding.modifiers)[0]}-${modifiers}`
    }
  }

  if (!/string/i.test(binding.value.constructor)) { binding.value = `${binding.value}px` }

  el.style[modifiers] = binding.value
}

function toprightbottomleftfontsize (el, binding) {
  /** @HowUse */
  /*
    acceptable parameters
      v-top
      v-right
      v-bottom
      v-left
  */

  /*
    acceptable values
      100   Number
      100px String
      100%  String
      100vw String
      100vh String
      100em String
  */
  /** @HowUse */

  if (!/string/i.test(binding.value.constructor)) { binding.value = `${binding.value}px` }

  el.style[binding.name] = binding.value
}

Vue.directive('width', function (el, binding) {
  widthheight(el, binding)
})

Vue.directive('height', function (el, binding) {
  widthheight(el, binding)
})

Vue.directive('padding', function (el, binding) {
  widthheight(el, binding)
})

Vue.directive('margin', function (el, binding) {
  widthheight(el, binding)
})

Vue.directive('top', function (el, binding) {
  toprightbottomleftfontsize(el, binding)
})

Vue.directive('right', function (el, binding) {
  toprightbottomleftfontsize(el, binding)
})

Vue.directive('bottom', function (el, binding) {
  toprightbottomleftfontsize(el, binding)
})

Vue.directive('left', function (el, binding) {
  toprightbottomleftfontsize(el, binding)
})

Vue.directive('font-size', function (el, binding) {
  toprightbottomleftfontsize(el, binding)
})

Vue.directive('cursor', function (el, binding) {
  el.style[binding.name] = binding.value
})

Vue.directive('overflow', function (el, binding) {
  el.style[binding.name] = binding.value
})

Vue.directive('color', function (el, binding) {
  el.style[binding.name] = binding.value
})

Vue.directive('background-color', function (el, binding) {
  el.style[binding.name] = binding.value
})

Vue.directive('line-height', function (el, binding) {
  el.style[binding.name] = binding.value
})

Vue.directive('position', function (el, binding) {
  el.style[binding.name] = binding.value
})

Vue.directive('opacity', function (el, binding) {
  el.style[binding.name] = binding.value
})
