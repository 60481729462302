export default {
  payload: {
    '_id': '',
    'origem': '',
    'segmento': '',
    'consumidor': {
      'cpf': '',
      'identidade': {
        'numero': '',
        'emissor': '',
        'uf': ''
      },
      'nome': '',
      'email': '',
      'telefone': {
        'contato': ''
      },
      'nascimento': '',
      'endereco': {
        'cep': '',
        'uf': '',
        'cidade': '',
        'logradouro': '',
        'numero': '',
        'bairro': '',
        'complemento': '',
        'referencia': ''
      },
      'geotag': {
        'ip': '',
        'latitude': '',
        'longitude': '',
        'regiao': ''
      }
    },
    'pedido': {
      'ciente': {
        'contrato': {
          'servico': ''
        },
        'instalacao': '',
        'recarga': ''
      },
      'produto': [],
      'pagamento': {
        'formaPagamento': 'cartaodecredito',
        'cartaoDeCredito': {
          'parcelas': '10'
        }
      },
      'postagem': {
        'servicoEntrega': '',
        'valorEntrega': 0
      },
      'valorTotal': ''
    },
    'lojaPropria': {
      'codigo': '',
      'nome': '',
      'regional': '',
      'nomeLoja': ''
    },
    'atendente': {
      'vertex': ''
    },
    'verificacaoEmail': {
      'enviado': false,
      'confirmado': false,
      'api': {
        'status': true
      }
    }
  }
}
